/**
 * Use this component for simple select
 * Component contains all Core UI CSelect attributes and options attribute
 */
 import PropTypes from "prop-types";
 import { CSelect } from "@coreui/react";
 const ASelect = (props) => {
   const { options, showDefaultOption, ...attributes } = props;
   return (
     <CSelect {...attributes}>
       {showDefaultOption && (
         <option value="" disabled>
           Select..
         </option>
       )}
 
       {options?.map((option, indx) => {
         const { value, label, ...attr } = option;
         return (
           <option value={value} key={value} {...attr}>
             {label}
           </option>
         );
       })}
     </CSelect>
   );
 };
 
 ASelect.propTypes = {
   options: PropTypes.arrayOf(PropTypes.object), //Options should  be in the form of array of object(label and value must be present in each object)
   showDefaultOption: PropTypes.bool, //it will show the "please select" item in dropdown
   defaultValue: PropTypes.string,
 };
 
 ASelect.defaultProps = {
   options: [],
   showDefaultOption: false,
 };
 export default ASelect;
 