/* eslint-disable react-hooks/exhaustive-deps */
import { CCol, CPagination, CRow } from "@coreui/react";
import ASelect from "../../../Components/ASelect";
import { mobileMode } from "../../../Resources/content";

function Pagination({
  setPageSize,
  records = 0,
  pages = 1,
  activePage,
  setActivePage,
  pageSize,
  pagesizes,
  setPages,
  isLoading,
  selectedItems,
  rowSelection="",
}) {
  const isMobile = mobileMode();
  const changepageSize = (e) => {
    const pSize = e.target.value;
    setPageSize(pSize);
    let totalPages = Math.ceil(records / Number(pSize));
    setPages(totalPages);
    setActivePage(1);
  };

  const handlePageChange = (page) => {
    if (page) {
      setActivePage(page);
    }
  };
  return isMobile ? (
    <CRow className="align-items-center m-0">
      <CCol xs="6">
        {rowSelection && rowSelection !== "none" && <div className="nowrap">Records Selected: {selectedItems?.length || 0}</div>}
        <div className="mt-1 nowrap">Total Records: {records}</div>
      </CCol>
      {records !== 0 &&
      <><CCol xs="6" className="d-flex align-items-center mb-1">
          <ASelect
            options={pagesizes}
            name="pageSize"
            defaultValue={pageSize}
            onChange={(e) => changepageSize(e)} />
          <span className="text-nowrap pl-1"> per page</span>
        </CCol><CCol xs="12">
            {(!isLoading && pages!==1 && records !== 0) && <CPagination
              activePage={activePage}
              onActivePageChange={handlePageChange}
              pages={pages}
              size="md"
              align="center"
              className="mt-1"
              disabled={isLoading} />}
          </CCol></>}
    </CRow>
  ) : (
    <CRow className="align-items-center m-0">
      <CCol xs="2">
        {rowSelection && rowSelection !== "none" && <div className="nowrap">Records Selected: {selectedItems?.length || 0}</div>}
        <div className="mt-1 nowrap">Total Records: {records}</div>
      </CCol>
      <CCol xs="7">
        {(!isLoading && pages!==1 && records !== 0) && <CPagination
          activePage={activePage}
          onActivePageChange={handlePageChange}
          pages={pages}
          // size="md"
          align="center"
          className="mt-2"
        />}
      </CCol>
      {records !== 0 ? 
      <CCol xs="3" className="d-flex align-items-center mb-1">
        <ASelect
          options={pagesizes}
          name="pageSize"
          value={pageSize}
          onChange={(e) => changepageSize(e)}
        />
        <span className="text-nowrap pl-1"> per page</span>
      </CCol>
      : <></>}
    </CRow>
  );
}

export default Pagination;
