/**
 * Use this Component for  Select dropdown.
 * Component contains all react-select(CreatableSelect) attributes
 */
import { useSelector } from "react-redux";
import Select from 'react-select'
import makeAnimated from "react-select/animated";
import {
  reactSelectStyles,
  reactSelectTheme,
} from "../views/WMS/Common_Helper/wms_style";

const AReactSelect = ({ ...props }) => {
  const darkMode = useSelector((state) => state.darkMode);
  const animatedComponents = makeAnimated();
  return (
    <Select
      components={animatedComponents}
      theme={(theme) => reactSelectTheme(theme, darkMode,props?.name)}
      styles={reactSelectStyles(darkMode)}
      {...props}
    />
  );
};

export default AReactSelect;

 