/**
 * Use this Component for Creatable Select dropdown.
 * Component comntains all react-select(CreatableSelect) attributes
 */
import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import {
  reactSelectStyles,
  reactSelectTheme,
} from "../views/WMS/Common_Helper/wms_style";
import { CForm } from "@coreui/react";

const ACreatableSelect = ({ ...props }) => {
  const darkMode = useSelector((state) => state.darkMode);
  const animatedComponents = makeAnimated();
  return (

      <CForm onSubmit={(e) => { e.preventDefault() }} className="w-100">
        <CreatableSelect
          components={animatedComponents}
          theme={(theme) => reactSelectTheme(theme, darkMode)}
          styles={reactSelectStyles(darkMode)}
          {...props}
        />
      </CForm>
  );
};

export default ACreatableSelect;
