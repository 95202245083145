import { getZoneFromLocalStorage } from "../../../Services/LocalStorageService";
import { OdataOrchestrationUrl, userAccessUrl } from "../../WMS/Common_Helper/API_Endpoints";

const GetZone = () => {
  let currentUrl = window.location.href;
  let zonee = "";
  if (currentUrl.includes("zone=")) {
    zonee = currentUrl?.split("zone=")[1];
    if (zonee?.includes("&")) {
      zonee = zonee.split("&")[0];
    }
    localStorage.setItem("zone", zonee);
  }
  return zonee;
};
let zone = GetZone();
if (!zone) {
  zone = getZoneFromLocalStorage();
}

export const gridBaseUrl = {
  AIMS360STYLES01: OdataOrchestrationUrl,
  AIMS360ORDERS01: OdataOrchestrationUrl,
  AIMS360CUST01: OdataOrchestrationUrl,
  AIMS360WD01:OdataOrchestrationUrl,
  AIMS360VENDORPO:OdataOrchestrationUrl,
  AIMS360WMSWT01:OdataOrchestrationUrl,
  AIMS360RWJOBS01:OdataOrchestrationUrl,
  AIMS360VPOBS01:OdataOrchestrationUrl,
  AIMS360OBS01:OdataOrchestrationUrl,
  AIMS360CPLOSANWFTP01:OdataOrchestrationUrl,
  AIMS360WMSPKT01:OdataOrchestrationUrl,
  AIMS360PKTBS01:OdataOrchestrationUrl,
  AIMS360MCSD01:OdataOrchestrationUrl,
  AIMS360RWSU01:userAccessUrl,
  AIMS360IIS01:OdataOrchestrationUrl,
  AIMS360RWSR01:userAccessUrl,
  AIMS360RWSW01:OdataOrchestrationUrl,
  AIMS360LRS01:OdataOrchestrationUrl,
  AIMS360IID01: OdataOrchestrationUrl,
  AIMS360WMSINQ01:OdataOrchestrationUrl,
  AIM360AQSCH01:OdataOrchestrationUrl,
  AIM360AQJBHSH01:OdataOrchestrationUrl
};
