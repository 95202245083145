// PubLinkCell.js (Define the PubLinkCell component)
import { CLink } from '@coreui/react';
import React from 'react';
import CopyButton from '../../../../Components/CopyButton';

const PubLinkCell = (item) => {
    return (
        <td className="d-flex align-items-center">
            {item?.["Publishing URL"] === 'Not Found' ? (
                <p className="m-0">Not Found</p>
            ) : (
                <>
                    <CLink href={item?.["Publishing URL"]} target='_blank'>{item?.["Publishing URL"]?.slice(0, 40)}</CLink>
                    <CopyButton textToCopy={item?.["Publishing URL"]} />
                </>
            )}
        </td>
    );
};

export default PubLinkCell;
