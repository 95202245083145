import moment from "moment";
import mt from 'moment-timezone'
import { GetSystemSettings} from "./LocalStorageService";
export const dateFormatFromApitoUi=(value,seconds=false)=>{
  const systemSettings = GetSystemSettings() ||{};
  let date_format='MM/DD/YYYY';
  let time_format='12 Hours AM/PM';
  let time_zone=localStorage.getItem('defaultTimeZone');
  if(systemSettings?.date_format){
    date_format=systemSettings.date_format;
  }
  if(systemSettings?.time_format){
    time_format=systemSettings.time_format; 
  }
  if(systemSettings?.time_zone){
    time_zone=systemSettings.time_zone;
  }
  if(Object.keys(systemSettings).length !== 0 && systemSettings.time_zone){
   time_zone = handleTimeZone(time_zone);
  }
      if (!value) {
        return '';
      }
      else if(Object.keys(systemSettings).length===0 && (!time_zone)){
        return value;
      } 
    else {
      if(value?.length===10){
        const localDateTime = moment(value)?.format(date_format);
        return localDateTime;
      }
      else{
       return  handleTime(value,time_format,time_zone,date_format, seconds);
    }
  }
    
}

const handleTime = (value,time_format,time_zone,date_format,seconds)=>{
    if( value!== 'undefined'){
      if(value?.includes("T")){
        let formattedDateTime='';
        formattedDateTime = time_format==='12 Hours AM/PM'?  mt.utc(value)?.tz(time_zone)?.format(date_format + `${seconds?' hh:mm:ss A ':' hh:mm A '}`): mt.utc(value)?.tz(time_zone)?.format(date_format+ `${seconds?' HH:mm:ss ':' HH:mm '}`);
        return formattedDateTime;
      }
      else{
        let timeWithoutSeconds='';
        timeWithoutSeconds = time_format==='12 Hours AM/PM'? moment(value)?.format(date_format + `${seconds?' hh:mm:ss A ':' hh:mm A '}`) : moment(value)?.format(date_format + `${seconds?' HH:mm:ss ':' HH:mm '}`)
        return timeWithoutSeconds;
      }
    }
    else{
      return '';
    }  
  
}

export const handleTimeZone = (time_zone)=>{
  if(time_zone){
    if(time_zone?.includes('UTC')){
      time_zone=time_zone?.split(' ')[1];
    }
  }
  return time_zone;
}
export const dateFormatFromUiToApi=(value)=>{
  let clientTimeZone=localStorage.getItem('defaultTimeZone');
  const offset = moment().tz(clientTimeZone).format('Z');
  const inputTime = moment(value, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').toDate();
  const convertedTime = moment(inputTime).utcOffset(offset).format('YYYY-MM-DDTHH:mm:ssZ');
  return convertedTime;
}