import { getLabel } from "../../../Resources/content"

export const StyleskeyMappings = {
  style: "Style",
  color: "Color",
  description: "Style Description",
  alternateDescription: "Alternate Style Description",
  colorDescription: "Color Description",
  type: "Type",
  status: "Style Status",
  stockTotal: "Stock Total",
  pickTotal: "Picked Total",
  wipTotal: "Wip Total",
  orderTotal: "Order Total",
  ots: "OTS",
  divisionCode: "Division",
  divisionName: "Division Name",
  styleGroupCode: "Group",
  styleGroupDescription: "Group Description",
  seasonCode: "Season",
  seasonName: "Season Name",
  scale: "Scale",
  bodyCode: "Body",
  bodyDescription: "Body Description",
  available: "Available",
  cancel: "Cancel",
  wholesalePrice: "Wholesale Price",
  replenishmentCode: "Replenishment Code",
  replenishmentDescription: "Replenishment Description",
  styleColorID: "Style Color ID",
  shipTotal: "Ship Total",
  minimumTotal: "Min Total",
  ots_i: "OTSi",
  createdByEmailAddress: "Created By",
  createdOn: "Created On",
  lastModifiedByEmailAddress: "Last Modified By",
  lastModified: "Last Modified On",
  UPCAssignedDate: "UPC Assigned Date",
  allocatedStockTotal: "Allocated Stock Total",
  allocatedWipTotal: "Allocated Wip Total",
  bodyID: "Body ID",
  brandName: "Brand Name",
  casePack: "Case Pack",
  colorID: "Color ID",
  createdByFirstName: "Created By First Name",
  createdByLastName: "Created By Last Name",
  damageColor: "Damage Color",
  damageStyle: "Damage Style",
  damageStyleID: "Damage Style ID",
  dimensions_uom: "Dimensions UOM",
  discountable: "Discountable",
  divisionAbbreviation: "Division Abbreviation",
  divisionID: "Division ID",
  generalNotes: "General Description",
  height: "Height",
  item_uom: "Item UOM",
  lastModifiedByFirstName: "LastModified By First Name",
  lastModifiedByLastName: "LastModified By Last Name",
  length: "Length",
  makeBuy: "Make Buy",
  makeCost: "Make Cost",
  minimumOrderQuantity: "Minimum Order Quantity",
  netWeight: "NetWeight",
  originCountryCode: "Origin Country Code",
  originCountryID: "Origin Country ID",
  originCountryName: "Origin Country Name",
  packagedWeight: "Packaged Weight",
  packagingType: "Packaging Type",
  prepack: "Prepack",
  purchaseCost: "Purchase Cost",
  replenishmentCodeID: "Replenishment Code ID",
  retailDescription: "Retail Description",
  rowVersion: "Row Version",
  scaleID: "Scale ID",
  seasonID: "Season ID",
  standardCommission: "Standard Commission",
  standardCommissionPercent: "Standard Commission Percent",
  standardPackQuantity: "Standard Pack Quantity",
  styleColorImages: "Style Color Images",
  styleColorTag: "Style Color Tag",
  styleColorUniqueID: "Style Color UniqueID",
  styleGroupID: "Style Group ID",
  styleID: "Style ID",
  styleImages: "Style Images",
  styleTag: "Style Tag",
  suggestedRetailPrice: "Suggested Retail Price",
  taxable: "Taxable",
  upcFormat: "UPC Format",
  userNote: "User Note",
  volume: "Volume",
  warehouseLocation: "Warehouse Location",
  webLink_remoteLink: "WebLink_remoteLink",
  webLink_remoteLink_price: "WebLink_remoteLink Price",
  weight_uom: "Weight UOM",
  wholesaleDescription: "Wholesale Description",
  width: "Width",
  year: "Year",
  dateEntered: "Date Entered",
  // "sizes__ots": "Size_OTS",
  // "sizes__sizeName": "Size",
  // "sizes__upc": "UPC",
  // "sizes__sizeStatus": "Size Status",
  // "sizes__ots_i": "Size_OTSi",
  // "sizes__styleColorSizeID": "Style Color Size ID",
  // "sizes__stockQuantity": "Stock Quantity",
  // "sizes__pickQuantity": "Picked Quantity",
  // "sizes__wipQuantity": "Wip Quantity",
  // "sizes__orderQuantity": "Order Quantity",
  // "sizes__shipQuantity": "Ship Quantity",
  // "sizes__minimumQuantity": "Min Quantity",
  // "sizes__netWeight": "Size_NetWeight",
  // "sizes__packagedWeight": "Size_Packaged Weight",
  // "sizes__sizePosition": "Size Position",
  // "sizes__weightUOM": "Size_Weight UOM",
  // "sizes__allocatedStockQuantity": "Allocated Stock Quantity",
  // "sizes__allocatedWipQuantity": "Allocated WIP Quantity"
}

export const StylesSizekeyMappings = {
  style: "Hdr_Style",
  color: "Hdr_Color",
  description: "Hdr_Style Description",
  alternateDescription: "Hdr_Alternate Style Description",
  colorDescription: "Hdr_Color Description",
  type: "Hdr_Type",
  status: "Hdr_Style Status",
  stockTotal: "Hdr_Stock Total",
  pickTotal: "Hdr_Picked Total",
  wipTotal: "Hdr_Wip Total",
  orderTotal: "Hdr_Order Total",
  ots: "Hdr_OTS",
  divisionCode: "Hdr_Division",
  divisionName: "Hdr_Division Name",
  styleGroupCode: "Hdr_Group",
  styleGroupDescription: "Hdr_Group Description",
  seasonCode: "Hdr_Season",
  seasonName: "Hdr_Season Name",
  scale: "Hdr_Scale",
  bodyCode: "Hdr_Body",
  bodyDescription: "Hdr_Body Description",
  available: "Hdr_Available",
  cancel: "Hdr_Cancel",
  wholesalePrice: "Hdr_Wholesale Price",
  replenishmentCode: "Hdr_Replenishment Code",
  replenishmentDescription: "Hdr_Replenishment Description",
  styleColorID: "Hdr_Style Color ID",
  shipTotal: "Hdr_Ship Total",
  minimumTotal: "Hdr_Min Total",
  ots_i: "Hdr_OTSi",
  createdByEmailAddress: "Hdr_Created By",
  createdOn: "Hdr_Created On",
  lastModifiedByEmailAddress: "Hdr_Last Modified By",
  lastModified: "Hdr_Last Modified On",
  UPCAssignedDate: "Hdr_UPC Assigned Date",
  allocatedStockTotal: "Hdr_Allocated Stock Total",
  allocatedWipTotal: "Hdr_Allocated Wip Total",
  bodyID: "Hdr_Body ID",
  brandName: "Hdr_Brand Name",
  casePack: "Hdr_Case Pack",
  colorID: "Hdr_Color ID",
  createdByFirstName: "Hdr_Created By First Name",
  createdByLastName: "Hdr_Created By Last Name",
  damageColor: "Hdr_Damage Color",
  damageStyle: "Hdr_Damage Style",
  damageStyleID: "Hdr_Damage Style ID",
  dimensions_uom: "Hdr_Dimensions UOM",
  discountable: "Hdr_Discountable",
  divisionAbbreviation: "Hdr_Division Abbreviation",
  divisionID: "Hdr_Division ID",
  generalNotes: "Hdr_General Description",
  height: "Hdr_Height",
  item_uom: "Hdr_Item UOM",
  lastModifiedByFirstName: "Hdr_LastModified By First Name",
  lastModifiedByLastName: "Hdr_LastModified By Last Name",
  length: "Hdr_Length",
  makeBuy: "Hdr_Make Buy",
  makeCost: "Hdr_Make Cost",
  minimumOrderQuantity: "Hdr_Minimum Order Quantity",
  netWeight: "Hdr_NetWeight",
  originCountryCode: "Hdr_Origin Country Code",
  originCountryID: "Hdr_Origin Country ID",
  originCountryName: "Hdr_Origin Country Name",
  packagedWeight: "Hdr_Packaged Weight",
  packagingType: "Hdr_Packaging Type",
  prepack: "Hdr_Prepack",
  purchaseCost: "Hdr_Purchase Cost",
  replenishmentCodeID: "Hdr_Replenishment Code ID",
  retailDescription: "Hdr_Retail Description",
  rowVersion: "Hdr_Row Version",
  scaleID: "Hdr_Scale ID",
  seasonID: "Hdr_Season ID",
  standardCommission: "Hdr_Standard Commission",
  standardCommissionPercent: "Hdr_Standard Commission Percent",
  standardPackQuantity: "Hdr_Standard Pack Quantity",
  styleColorImages: "Hdr_Style Color Images",
  styleColorTag: "Hdr_Style Color Tag",
  styleColorUniqueID: "Hdr_Style Color UniqueID",
  styleGroupID: "Hdr_Style Group ID",
  styleID: "Hdr_Style ID",
  styleImages: "Hdr_Style Images",
  styleTag: "Hdr_Style Tag",
  suggestedRetailPrice: "Hdr_Suggested Retail Price",
  taxable: "Hdr_Taxable",
  upcFormat: "Hdr_UPC Format",
  userNote: "Hdr_User Note",
  volume: "Hdr_Volume",
  warehouseLocation: "Hdr_Warehouse Location",
  webLink_remoteLink: "Hdr_WebLink_remoteLink",
  webLink_remoteLink_price: "Hdr_WebLink_remoteLink Price",
  weight_uom: "Hdr_Weight UOM",
  wholesaleDescription: "Hdr_Wholesale Description",
  width: "Hdr_Width",
  year: "Hdr_Year",
  dateEntered: "Hdr_Date Entered",
  "sizes__ots": "OTS",
  "sizes__sizeName": "Size",
  "sizes__upc": "UPC",
  "sizes__sizeStatus": "Size Status",
  "sizes__ots_i": "OTSi",
  "sizes__styleColorSizeID": "Style Color Size ID",
  "sizes__stockQuantity": "Stock Quantity",
  "sizes__pickQuantity": "Picked Quantity",
  "sizes__wipQuantity": "Wip Quantity",
  "sizes__orderQuantity": "Order Quantity",
  "sizes__shipQuantity": "Ship Quantity",
  "sizes__minimumQuantity": "Min Quantity",
  "sizes__netWeight": "NetWeight",
  "sizes__packagedWeight": "Packaged Weight",
  "sizes__sizePosition": "Size Position",
  "sizes__weightUOM": "Weight UOM",
  "sizes__allocatedStockQuantity": "Allocated Stock Quantity",
  "sizes__allocatedWipQuantity": "Allocated WIP Quantity"
}

export const StyleSizeskeyMappings = {
  style: "Hdr_Style",
  color: "Hdr_Color",
  description: "Hdr_Style Description",
  alternateDescription: "Hdr_Alternate Style Description",
  colorDescription: "Hdr_Color Description",
  type: "Hdr_Type",
  status: "Hdr_Style Status",
  stockTotal: "Hdr_Stock Total",
  pickTotal: "Hdr_Picked Total",
  wipTotal: "Hdr_Wip Total",
  orderTotal: "Hdr_Order Total",
  ots: "Hdr_OTS",
  divisionCode: "Hdr_Division",
  divisionName: "Hdr_Division Name",
  styleGroupCode: "Hdr_Group",
  styleGroupDescription: "Hdr_Group Description",
  seasonCode: "Hdr_Season",
  seasonName: "Hdr_Season Name",
  scale: "Hdr_Scale",
  bodyCode: "Hdr_Body",
  bodyDescription: "Hdr_Body Description",
  available: "Hdr_Available",
  cancel: "Hdr_Cancel",
  wholesalePrice: "Hdr_Wholesale Price",
  replenishmentCode: "Hdr_Replenishment Code",
  replenishmentDescription: "Hdr_Replenishment Description",
  styleColorID: "Hdr_Style Color ID",
  sizeName: "Size",
  upc: "UPC",
  sizeStatus: "Size Status",
  shipTotal: "Hdr_Ship Total",
  minimumTotal: "Hdr_Min Total",
  ots_i: "Hdr_OTSi",
  createdByEmailAddress: "Hdr_Created By",
  createdOn: "Hdr_Created On",
  lastModifiedByEmailAddress: "Hdr_Last Modified By",
  lastModified: "Hdr_Last Modified On",
  styleColorSizeID: "Style Color Size ID",
  stockQuantity: "Stock Quantity",
  pickQuantity: "Picked Quantity",
  wipQuantity: "Wip Quantity",
  orderQuantity: "Order Quantity",
  shipQuantity: "Ship Quantity",
  minimumQuantity: "Min Quantity",
  UPCAssignedDate: "Hdr_UPC Assigned Date",
  allocatedStockTotal: "Hdr_Allocated Stock Total",
  allocatedWipTotal: "Hdr_Allocated Wip Total",
  bodyID: "Hdr_Body ID",
  brandName: "Hdr_Brand Name",
  casePack: "Hdr_Case Pack",
  colorID: "Hdr_Color ID",
  createdByFirstName: "Hdr_Created By First Name",
  createdByLastName: "Hdr_Created By Last Name",
  damageColor: "Hdr_Damage Color",
  damageStyle: "Hdr_Damage Style",
  damageStyleID: "Hdr_Damage Style ID",
  dimensions_uom: "Hdr_Dimensions UOM",
  discountable: "Hdr_Discountable",
  divisionAbbreviation: "Hdr_Division Abbreviation",
  divisionID: "Hdr_Division ID",
  generalNotes: "Hdr_General Description",
  height: "Hdr_Height",
  item_uom: "Hdr_Item UOM",
  lastModifiedByFirstName: "Hdr_LastModified By First Name",
  lastModifiedByLastName: "Hdr_LastModified By Last Name",
  length: "Hdr_Length",
  makeBuy: "Hdr_Make Buy",
  makeCost: "Hdr_Make Cost",
  minimumOrderQuantity: "Hdr_Minimum Order Quantity",
  netWeight: "Hdr_NetWeight",
  originCountryCode: "Hdr_Origin Country Code",
  originCountryID: "Hdr_Origin Country ID",
  originCountryName: "Hdr_Origin Country Name",
  packagedWeight: "Hdr_Packaged Weight",
  packagingType: "Hdr_Packaging Type",
  prepack: "Hdr_Prepack",
  purchaseCost: "Hdr_Purchase Cost",
  replenishmentCodeID: "Hdr_Replenishment Code ID",
  retailDescription: "Hdr_Retail Description",
  rowVersion: "Hdr_Row Version",
  scaleID: "Hdr_Scale ID",
  seasonID: "Hdr_Season ID",
  standardCommission: "Hdr_Standard Commission",
  standardCommissionPercent: "Hdr_Standard Commission Percent",
  standardPackQuantity: "Hdr_standard Pack Quantity",
  styleColorImages: "Style Color Images",
  styleColorTag: "Hdr_Style Color Tag",
  styleColorUniqueID: "Hdr_Style Color UniqueID",
  styleGroupID: "Hdr_Style Group ID",
  styleID: "Hdr_Style ID",
  styleImages: "Style Images",
  styleTag: "Hdr_Style Tag",
  suggestedRetailPrice: "Hdr_Suggested Retail Price",
  taxable: "Hdr_Taxable",
  upcFormat: "Hdr_UPC Format",
  userNote: "Hdr_User Note",
  volume: "Hdr_Volume",
  warehouseLocation: "Hdr_Warehouse Location",
  webLink_remoteLink: "Hdr_WebLink_remoteLink",
  webLink_remoteLink_price: "Hdr_WebLink_remoteLink Price",
  weight_uom: "Weight UOM",
  wholesaleDescription: "Hdr_Wholesale Description",
  width: "Hdr_Width",
  year: "Hdr_Year",
  dateEntered: "Hdr_Date Entered",
  sizePosition: "Size Position",
  weightUOM: "Weight UOM",
  allocatedStockQuantity: "Allocated Stock Quantity",
  allocatedWipQuantity: "Allocated Wip Quantity",
  sizes__ots: "OTS",
  sizes__ots_i: "OTSi",
  sizes__packagedWeight: "Packaged Weight",
  sizes__netWeight: "Net Weight"
}

export const rmaFailedRecordHeaders={
  ReturnReason:"Return Reason",
  StockReceiveWarehouse:"Stock Receive Warehouse",
  CustomerPO:"Customer PO",
  AuthorizeBy:"Authorize By",
  AuthorizeQTY:"Authorize QTY",
  AuthorizePrice:"Authorize Price",
  reference:"Reference",
  error:"Error",
  validationStatus:"Validation Status"
}

export const RmaOrderStatus={
  configurationId:"Configuration Id",
  shopifyStoreName :"Shopify Store Name",
  shopifyStoreId:"Shopify Order ID",
  loopRmaNo :"Loop RMA#",
  creditMemoStatus:"Credit Memo Status",
  aims360RmaNo :"AIMS360 RMA#",
  dateTimeReceived :"Date Received",
  aims360CreationDatetime: "AIMS360 RMA Create Date",
  failureReason:"Failed Reason",
  triesToCreateAims360Rma:"No of attempts to create AIMS360 RMA",
  triesToUpdateLoopStatus:"No of attempts to update Loop RMA",
  loopReturnJobId:"loopReturnJobId",
  loopWebhookRequestBody:"Loop RMA Webhook Request Body",
  aimswebhookRequestBody:"AIMS360 Webhook Request Body",
  aims360jobId:"aims360jobId",
  Reprocess:"Reprocess",
}

export const WarehouseOrderList={
  status:"Status",
  warehouseCode:"Warehouse Code",
  warehouseName:"Warehouse Name",
  warehouseID:"Warehouse ID",
  isDefault:"IsDefault",
  address1:"Address 1",
  address2:"Address 2",
  address3:"Address 3",
  city:"City",
  state:"State",
  zipCode:"Zip Code",
  countryID:"Country ID",
  countryCode:"Country Code",
  countryName:"Country Name",
  phone:"Phone",
  fax:"Fax",
  email:"Email",
  notes:"Notes",
  uniformLocationCode:"Uniform Location Code",
  allowZeroStockPicking:"Allow Zero Stock Picking",
  EnableBinLocations:"Enable BinLocations",
  rowVersion:"Row Version"

}

export const sageConfiguration ={
  termID: "Term Code Id",
  termCode:"AIMS360 Term Code",
  termDescription:"AIMS360 Term Code Description",
  discountPercentage: "Discount Percentage",
  EOM:"EOM",
  termDays:"Term Days",
  creditCardTerms:"Credit Card Terms",
  rowVersion:"Row Version",
  sageTermCode:"Sage Term Code"

}

export const aimsCustomers={
  customerAccount:"AIMS360Acc",
  customerName:"AIMS Name",
  email:"Email",
  lastModified:"Last Modified Date Time"
}
export const quickBooksCustomerKey={
  lastUpdatedTime:"Last Modified Date Time",
  companyName:"QB Company Name",
  displayName:"QB Display Name",
  email:"Email"
}


export const exportCustomers={
  customerName:"Customer Name",
  customerAccount:"Customer Account",
  email:"Email",
  currencyCode:"Currency Code",
  createdBy:"Created By",
  createdOn:"Created On",
  isExported:"Export Status",
  lastModified: "Last Updated On",
  exportedOn : "Last Exported On",
  batchNo : "Batch No",
  customerID: "Customer ID",
  status: "Status",
  countryID: "Country ID",
  countryCode: "Country Code",
  countryCodeLong: "Country Code Long",
  countryName: "Country Name",
  currencyID: "Currency ID",
  currencyName: "Currency Name",
  age30: "Age30",
  age30TC: "Age30TC",
  age60: "Age60",
  age60TC: "Age60TC",
  age90: "Age90",
  age90TC: "Age90TC",
  age120: "Age120",
  age120TC: "Age120TC",
  createdByFirstName: "Created By FirstName",
  createdByLastName: "Created By LastName",
  address1: "Address1",
  address2: "Address2",
  address3: "Address3",
  city: "City",
  state: "State",
  zipCode: "ZipCode",
  termCode: "TermCode",
}



export const InprogressJobs ={
  Id:"ID",
  JobId:"Job Id",
  JobDetails:"Job Details",
  JobRunId:"Job Run Id",
  JobType:"Import Type",
  ModuleType:"Module Type",
  Params:"Params",
  TriggerTime :"Import Date",
  Status:"Status",
  StatusText:"Status Text",
  StorageConnectionString:"Storage Connection String",
  PublishLink:"Publish Link",
  UserEmail:"User Email",
  FileName:"File Name",
  ContainerName:"Container Name",
  CanRerun:"Can Run",
  ClientId:"Client Id",
  PurgeDate:"Purge Date",
  NoOfPosProcessed:"No  Of Pos Processed",
  SuccessCount:"Success",
  FailureCount:"Failed",
  Category:"Category",
  TimeOutMinutes:"Time Out Minutes",
  RequestParameters:"Request Parameters",
  CreatedBy:"CreatedBy",
  CreatedOn:"Created On",
  UserId:"User Id",
  batch:"Batch ID"
  }
  

export const notExportInvoice={
  invoice:"Invoice No",
  customerName:"Customer Name",
  termCode:"Term Code",
  invoiceDate:"Invoice Date",
  dueDate:"Invoice Due Date",
  orderSource:"Order Source",
  exportStatus:"Export Status",
  batchNo:"Batch No",
  lastModified : "Last Modified Date",
  exportedDate : "Exported Date",
  invoiceID: "Invoice ID",
  thirdPartyOrderID: "Third Party OrderID",
  shopifyStoreID: "Shopify StoreID",
  shopifyStoreName: "Shopify StoreName",
  createdOn: "Created On",
  totalAmount: "Total Amount",
  currencyID: "Currency ID",
  currencyName: "Currency Name",
  currency: "Currency",
  currencySymbol: "Currency Symbol",
  status: "Status",
  merchandise: "Merchandise",
  totalDiscountAmount: "Total Discount Amount",
  localTaxAmount: "Local TaxAmount",
  stateTaxAmount: "State TaxAmount",
  federalTaxAmount: "Federal TaxAmount",
  freightAmount: "Freight Amount",
  customerAccount: "Customer Account"
}

export const ExportInvoice={
  customerName:"Customer Name",
  customerAccount : "Customer Account",
  termCode:"Term Code",
  invoiceDate:"Invoice Date",
  dueDate:"Invoice Due Date",
  orderSource:"Order Source",
  exportStatus:"Export Status",
  batchNo:"Batch No",
  lastModified : "Last Modified Date",
  exportedDate : "Exported Date",
  invoice : "Invoice No",
  thirdPartyOrderID: "Third Party OrderID",
  shopifyStoreID: "Shopify StoreID",
  shopifyStoreName: "Shopify StoreName",
  createdOn: "Created On",
  totalAmount: "Total Amount",
  currencyID: "Currency ID",
  currencyName: "Currency Name",
  currency: "Currency",
  currencySymbol: "Currency Symbol",
  status: "Status",
  merchandise: "Merchandise",
  totalDiscountAmount: "Total Discount Amount",
  localTaxAmount: "Local TaxAmount",
  stateTaxAmount: "State TaxAmount",
  federalTaxAmount: "Federal TaxAmount",
  freightAmount: "Freight Amount",

}

export const batchDaetails={
  invoice:"Invoice No",
  customerName:"Customer Name",
  termCode:"Term Code",
  invoiceDate:"Invoice Date",
  dueDate:"Invoice Due Date",
  orderSource:"Order Source",
  status:"Status",
  batchNo:"Batch No",
  invoiceID: "Invoice ID",
  thirdPartyOrderID: "Third Party OrderID",
  shopifyStoreID: "Shopify StoreID",
  shopifyStoreName: "Shopify StoreName",
  createdOn: "Created On",
  totalAmount: "Total Amount",
  currencyID: "Currency ID",
  currencyName: "Currency Name",
  currency: "Currency",
  currencySymbol: "Currency Symbol",
  merchandise: "Merchandise",
  totalDiscountAmount: "Total DiscountAmount",
  localTaxAmount: "Local TaxAmount",
  stateTaxAmount: "State TaxAmount",
  federalTaxAmount: "Federal TaxAmount",
  freightAmount: "Freight Amount",
  exportStatus: "Export Status",
  lastModified: "Last Modified",
  exportedDate: "Exported Date",
  customerAccount: "Customer Account"
}

export const losanExcelFTP ={
  JobId:"Job ID",
  TriggerTime:"Trigger Date Time",
  FileName:"File Name",
  JobType:'Job Type',
  Status:"Status",
  StatusText:"Message",
  Action:"Action",
}


export const runwayUniversalJobs = {
  ModuleType : "Module Type",
  JobType : "Job Type",
  TriggerTime : "Job Start Time",
  JobEndTime:"Job End Time",
  Inprogress:"In Progress",
  SuccessCount : "Success",
  FailureCount : "Failure",
  Status : "Status",
  StatusText:"Message",
  CreatedBy : "Created By",
  FileName : "File Name",
  JobId : "Job ID",
  Id:"ID",
  JobDetails : "Job Details",
  JobRunId : "Job Run ID",
  UserEmail : "User Email",
  ClientId : "Client ID",
  ContainerName : "Container Name",
  CanRerun:"Can Rerun",
  PurgeDate : "Purge Date",
  TimeOutMinutes : "Time Out Minutes",
  CreatedOn : "Created On",
  UserId:"User ID",
  PublishLink : "Publish Link",
  TriggerType :"Trigger Type"
}

export const sizeParameterKeys = [
  "sizeName",
  "sizeStatus",
  "sizePosition",
  "weightUOM",
  "packagedWeight",
  "netWeight",
  "minimumQuantity",
  "stockQuantity",
  "pickQuantity",
  "wipQuantity",
  "orderQuantity",
  "shipQuantity",
  "allocatedStockQuantity",
  "allocatedWipQuantity",
  "ots_i",
  "ots",
  "upc",
  "styleColorSizeID"
]

export const stylesNumberkeys = [
  "makeBuy",
  "wholesalePrice",
  "suggestedRetailPrice",
  "makeCost",
  "purchaseCost",
  "standardCommissionPercent",
  "allocatedStockTotal",
  "allocatedWipTotal",
  "stockTotal",
  "pickTotal",
  "wipTotal",
  "orderTotal",
  "shipTotal",
  "minimumTotal",
  "ots_i",
  "ots",
  "length",
  "width",
  "height",
  "volume",
  "netWeight",
  "packagedWeight",
  "standardPackQuantity",
  "minimumOrderQuantity",
  "webLink_remoteLink_price",
  "rowVersion",
  "sizePosition",
  "weightUOM",
  "packagedWeight",
  "standardPackQuantity",
  "minimumQuantity",
  "stockQuantity",
  "pickQunatity",
  "wipQuantity",
  "orderQuantity",
  "shipQuantity",
  "allocatedStockQuantity",
  "allocatedWipQuantity",
  //uniqueIdentifiers(GUID)
  "styleColorID",
  "colorID",
  "seasonID",
  "divisionID",
  "styleGroupID",
  "replenishmentCodeID",
  "scaleID",
  "originCountryID",
  "damageStyleID",
  "styleColorSizeID",
]

export const universalJobsNumberkeys = [
"JobId",
"JobDetails",
"JobRunId",
"Id"
]
export const aquaExportHeaderMappins={
  createdBy:"Created By",
  ID: "ID",
  scheduledJobName: "Job Name",
  status: "Job Status",
  description: "Job Description",
  pubLink: "Publishing URL",
  scheduledJobID :'Scheduled Job ID',
  recurrenceType:'Recurrence Type',
  startDateTime:'Start Date',
  createdDateTime:'Created Date',
  recurrence__frequency:'Frequency',
  recurrence__interval:'Interval',
  recurrence__week__days:'Days',
  recurrence__day:'Day',
  recurrence__week:'Week',
  recurrence__week__hours:'Hours',
  recurrence__week__minutess:'Minutes'
}

export const warehouseTransfer={
  WarehouseTransferID:"Warehouse Transfer ID",
  WarehouseTransferNumber:getLabel("Transfer ID"),
  SourceWarehouseName:getLabel("Source Warehouse"),
  DestinationWarehouseName:getLabel("Destination Warehouse"),
  TransferTotal:getLabel("Transfer QTY"),
  OpenTotal:"Open QTY",
  ReceivedTotal:getLabel("Received QTY"),
  CreatedOn:getLabel("Created On Date"),
  Status:"Status",
  SourceWarehouseID: "Source Warehouse ID",
  SourceWarehouseCode: "Source Warehouse Code",
  DestinationWarehouseID: "Destination Warehouse ID",
  DestinationWarehouseCode: "Destination Warehouse Code",
  CancelledTotal: "Cancelled Total",
  StartDate: "Start Date",
  TrackingNumber: "Tracking Number",
  Reference: "Reference",
  DamageTotal:"Damage Total",
  ShipviaID: "Shipvia ID",
  ShipviaCode: "Shipvia Code",
  ShipviaDescription: "Shipvia Description",
  CompletedOn: "Completed On",
  LastModifiedOn: "Last Modified On",
  CreatedByOperatorID: "Created By Operator ID",
  CreatedByFirstName: "Created By FirstName",
  CreatedByLastName: "Created By LastName",
  CreatedByEmailAddress: "Created By Email Address",
  ModifiedByOperatorID: "Modified By Operator ID",
  LastModifiedByFirstName: "Last Modified By FirstName",
  LastModifiedByLastName: "Last Modified By LastName",
  LastModifiedByEmailAddress: "Last Modified By Email Address"
}

export const importJobNumberKey = [
"id",
"noOfPosProcessed",
"successCount",
"failureCount",
"timeOutMinutes"
]

export const warehouseTransferKeys=[
  "TransferTotal",
  "OpenTotal",
  "ReceivedTotal",
  "DamageTotal",
  "CancelledTotal",
  "CreatedOn",
  "WarehouseTransferID",
  "SourceWarehouseID",
  "ShipviaID",
  "DestinationWarehouseID",
  "CreatedByOperatorID"
]

export const invoiceNumberKeys =[
  "merchandise",
  "totalDiscountAmount",
  "localTaxAmount",
  "stateTaxAmount",
  "federalTaxAmount",
  "freightAmount",

]


export const invoiceDatekeys = [
  "invoiceDate",
  "dueDate",
  "lastModified",
  "exportedDate",
  "createdOn",
  "exportedOn"
]

export const customerNumberKeys = [
  "age30",
  "age30TC",
  "age60",
  "age60TC",
  "age90",
  "age90TC",
  "age120",
  "age120TC",
]

export const customFieldData ={
  displayName:"Field Name",
  Read:"Read",
  Write:"Write"
}

export const PickticketsHeader = {
pickTicketID: "Pick Ticket ID",
pickTicket: "Pick Ticket",
orderID: "Order ID",
order: "Order",
customerPurchaseOrder: "Customer Purchase Order",
customerID: "Customer ID",
customerAccount: "Customer Account",
customerName: "Customer Name",
status: "Status",
shipToStoreID: "Ship To Store ID",
shipToStoreCode: "Ship To Store Code",
shipToStoreName: "Ship To Store Name",
shipToAddress1: "Ship To Address1",
shipToAddress2: "Ship To Address2",
shipToAddress3: "Ship To Address3",
shipToCity: "Ship To City",
shipToState: "Ship To State",
shipToZip: "Ship To Zip",
shipToCountryID: "Ship To Country ID",
shipToCountryCode: "Ship To Country Code",
shipToCountryName: "Ship To Country Name",
distributionCenterID: "Distribution Center ID",
distributionCenterCode: "Distribution Center Code",
distributionCenterName: "Distribution Center Name",
warehouseID: "Warehouse ID",
warehouseCode: "Warehouse Code",
warehouseName: "Warehouse Name",
exportDate: "Export Date",
seasonID: "Season ID",
seasonCode: "Season Code",
seasonName: "Season Name",
divisionID: "Division ID",
divisionCode: "Division Code",
divisionName: "Division Name",
divisionAbbreviation: "Division Abbreviation",
warehouseInvoice: "Warehouse Invoice",
totalAmount: "Total Amount",
printCount: "Print Count",
assigneeOperatorID: "Assignee Operator ID",
assigneeFirstName: "Assignee First Name",
assigneeLastName: "Assignee Last Name",
assigneeEmailAddress: "Assignee Email Address",
pickDate: "Pick Date",
shipDate: "Ship Date",
thirdPartyOrderCreateDate: "Third Party Order Create Date",
shipviaID: "Ship via ID",
shipviaCode: "Ship via Code",
shipviaDescription: "Ship via Description",
scac: "Scac",
rateCode: "Rate Code",
packagesCount: "Packages Count",
totalWeight: "Total Weight",
tracking: "Tracking",
bol: "Bol",
pickTotal: "Pick Total",
packTotal: "Pack Total",
merchandise: "Merchandise",
discountAmount: "Discount Amount",
freightAmount: "Freight Amount",
additionalFreightAmount: "Additional Freight Amount",
localTaxAmount: "Local Tax Amount",
stateTaxAmount: "State Tax Amount",
federalTaxAmount: "Federal Tax Amount",
pickId: "Pick Id",
createdOn: "Created On",
createdByFirstName: "Created By First Name",
createdByLastName: "Created By Last Name",
createdByEmailAddress: "Created By EmailAddress",
lastModified: "Last Modified",
lastModifiedByFirstName: "LastModified By First Name",
lastModifiedByLastName: "LastModified By Last Name",
lastModifiedByEmailAddress: "LastModified By Email Address",
rowVersion: "Row Version",
}

export const OrderUnitsHeader = {
orderID: "Order ID",
order: "Order",
status: "Status",
orderSource: "Order Source",
createdOn: "Created On",
allocatedTotal: "Allocated Total",
openTotal: "Open Total",
bookedAmount: "Booked Amount",
orderStatusReasonID: "Order Status Reason ID",
orderStatusReasonCode: "Order Status Reason Code",
orderStatusReasonDescription: "Order Status Reason Description",
customerID: "Customer ID",
customerAccount: "Customer Account",
customerName: "Customer Name",
currencyID: "Currency ID",
currencyCode: "Currency Code",
currencyName: "Currency Name",
currencySymbol: "Currency Symbol"
}

export const TransfersHeader = {
warehouseTransferID: "Warehouse Transfer ID",
warehouseTransferNumber: "Warehouse Transfer Number",
sourceWarehouseName: "Source Warehouse Name",
destinationWarehouseName: "Destination Warehouse Name",
transferTotal: "Transfer Total",
openTotal: "Open Total",
receivedTotal: "Received Total",
damageTotal: "Damage Total",
cancelledTotal: "Cancelled Total",
status: "Status",
startDate: "Start Date",
trackingNumber: "Tracking Number",
reference: "Reference",
shipviaID: "Ship via ID",
shipviaCode: "Ship via Code",
shipviaDescription: "Ship via Description",
completedOn: "Completed On",
createdOn: "Created On",
lastModifiedOn: "LastModified On"
}

export const RMAsHeaders = {
rmaID: "Rma ID",
rma: "Rma",
customerID: "Customer ID",
customerAccount: "Customer Account",
customerName: "Customer Name",
status: "Status",
authorizedQuantity: "Authorized Quantity",
authorizedAmount: "Authorized Amount",
currencyID: "Currency ID",
currencyCode: "Currency Code",
currencyName: "Currency Name",
currencySymbol: "Currency Symbol",
rmaDate: "Rma Date",
rmaReasonID: "Rma Reason ID",
rmaReasonCode: "Rma Reason Code",
rmaReasonDescription: "Rma Reason Description",
warehouseID: "Warehouse ID",
warehouseCode: "Warehouse Code",
warehouseName: "Warehouse Name"
}

export const actionGridNumberKeys = [
"totalAmount",
"printCount",
"packagesCount",
"totalWeight",
"pickTotal",
"packTotal",
"merchandise",
"discountAmount",
"freightAmount",
"additionalFreightAmount",
"localTaxAmount",
"stateTaxAmount",
"federalTaxAmount",
"pickId",
"rowVersion",
"allocatedTotal",
"openTotal",
"bookedAmount",
'totalAmount',
'printCount',
'packagesCount',
'totalWeight',
'pickTotal',
'packTotal',
'merchandise',
'discountAmount',
'freightAmount',
'additionalFreightAmount',
'localTaxAmount',
'stateTaxAmount',
'federalTaxAmount',
'pickId',
'rowVersion',
'price_tc',
'price',
'openTotal',
'bookQuantity',
'packQuantity',
'pickQuantity',
'sizePosition',
'shipQuantity',
'cancelQuantity',
'openQuantity',
'transferTotal',
'openTotal',
'receivedTotal',
'damageTotal',
'cancelledTotal',
'authorizedQuantity',
'authorizedAmount',
]

export const actionGridDateKeys = [
"exportDate",
"pickDate",
"shipDate",
"thirdPartyOrderCreateDate",
"createdOn",
"lastModified",
"startDate",
"completedOn",
"lastModifiedOn",

]

export const customerGridHeaders = {
  customerID : "Customer ID",
  customerAccount : "Customer Account",
  customerName : "Customer Name",
  status : "Status",
  countryID : "Country ID",
  countryCode : "Country Code",
  countryCodeLong : "Currency",
  countryName : "Country Name",
  currencyID : "Currency ID",
  currencyCode : "Currency Code",
  currencyName : "Currency Name",
  age30 : "Age30",
  age30TC : "Age30TC",
  age60 : "Age60",
  age60TC : "Age60TC",
  age90 : "Age90",
  age90TC : "Age90TC",
  age120 : "Age120",
  age120TC : "Age120TC",
  createdOn : "Created On",
  lastModified : "Last Modified",
  email : "Email",
  createdByFirstName : "Created By First Name",
  createdByLastName : "Created By Last Name",
  isExported : "Is Exported",
  address1 : 'Address 1',
  address2 : "Address 2",
  address3 : "Address 3",
  city : "City",
  state : "State",
  zipCode : "Zip Code",
  termCode : "Term",
  ExportStatus : "Export Status",
  ExportedOn : "Exported On",
  batchNo : "Batch No",
  salesRep1Name:"Sales Representative",
  preferredWarehouseName:"Preferred Warehouse",
  accountPayableContact:"Account Payable Contact",
  additionalFreightAmount:"Additional Freight Amount",
  additionalFreightPercentage:"Additional Freight Percentage",
  autoReceiveInvoiceEmails:"Auto Receive Emails",
  billing:"Billing",
  buyer:"Buyer",
  cod:"Cod",
  createdByEmailAddress:"Created By Email Address",
  creditAvail:"Credit Avail",
  creditAvailTC:"Credit Avail TC",
  creditCardProcessingFeePercentage:"Credit Card Processing Fee Percentage",
  creditLimit:"Credit Limit",
  currentAR:"Current AR",
  currentARTC:"Current ARTC",
  customerAddedDate:"Customer Added Date",
  customerClassificationCode:"Customer Classification Code",
  customerClassificationDescription:"Customer Classification Description",
  customerClassificationID:"Customer Classification ID",
  customerPurchaseOrderRequired:"Customer Purchase Order Required",
  dba:"DBA",
  debtor:"Debtor",
  defaultPackingRule:"Default Packing Rule",
  discountReasonCode:"Discount Reason Code",
  discountReasonDescription:"Discount Reason Description",
  discountReasonID:"Discount Reason ID",
  discretionaryDiscountPercentage:"Discretionary Discount Percentage",
  divisionName:"Division Name",
  divisionAbbreviation:"Division Abbreviation",
  divisionCode:"Division Code",
  divisionID:"Division ID",
  duns:"Duns",
  exportPickTicketsToShipstation:"Export Pick Tickets To Shipstation",
  factorAccount:"Factor Account",
  factorID:"Factor ID",
  factorName:"Factor Name",
  fax:"Fax",
  federalTax:"Federal Tax",
  fedexAccount:"Fedex Account",
  freeFormAddress:"Free Form Address",
  freightCollect:"Freight Collect",
  invoiceEmailOptions_receiveInvoiceEmails:"Invoice Email Options Receive Invoice Emails",
  invoiceEmailOptions_toCCBCC:"Invoice Email Options To CCBCC",
  isFreeFormAddress: "Is Free Form Address",
  lastModifiedByEmailAddress: "Last Modified By Email Address",
  lastModifiedByFirstName: "Last Modified By First Name",
  lastModifiedByLastName: "Last Modified By Last Name",
  lineItemDiscountPercentage: "Line Item Discount Percentage",
  lineItemDiscountType: "Line Item Discount Type",
  lineItemDiscountValueType: "Line Item Discount Value Type",
  localTax: "Local Tax",
  marketRegionCode: "Market Region Code",
  marketRegionDescription: "Market Region Description",
  marketRegionID: "Market Region ID",
  netDiscountPercentage: "Net Discount Percentage",
  note: "Note",
  orderStatus: "Order Status",
  orderStatusReasonCode: "Order Status Reason Code",
  orderStatusReasonDescription: "Order Status Reason Description",
  orderStatusReasonID: "Order Status Reason ID",
  packingNotes: "Packing Notes",
  perUnitLineItemDiscountAmount: "Per Unit Line Item Discount Amount",
  phone: "Phone",
  preferredPaymentMethod: "Preferred Payment Method",
  preferredWarehouseCode: "Preferred Warehouse Code",
  preferredWarehouseID: "Preferred Warehouse ID",
  priceSheetCategoryID: "Price Sheet Category ID",
  priceSheetCategoryName: "Price Sheet Category Name",
  priority: "Priority",
  resale: "Resale",
  residentialAddress: "Residential Address",
  retailCustomer: "Retail Customer",
  rowVersion: "Row Version",
  salesRep1Code: "Sales Rep1 Code",
  salesRep1CommissionPercent: "Sales Rep 1 Commission Percent",
  salesRep1ID: "Sales Rep1 ID",
  salesRep2Code: "Sales Rep2 Code",
  salesRep2commissionPercent: "Sales Rep 2 Commission Percent",
  salesRep2ID: "Sales Rep2 ID",
  salesRep2Name: "Sales Rep 2 Name",
  shipComplete: "Ship Complete",
  shippingInstructions: "Shipping Instructions",
  shipviaCode: "Shipvia Code",
  shipviaDescription: "Shipvia Description",
  shipviaID: "Shipvia ID",
  specialInstructionCode: "Special Instruction Code",
  specialInstructionDescription: "Special Instruction Description",
  specialInstructionID: "Special Instruction ID",
  stateTax: "State Tax",
  termDescription: "Term Description",
  termID: "Term ID",
  totalAR: "Total AR",
  totalARTC: "Total AR TC",
  tradingPartnerCode: "Trading Partner Code",
  UPSAccount: "UPS Account",
  vasNotes: "VAS Notes",
  vendor: "Vendor",
  website: "Website"

}

export const customerGridNumberKeys = [
  "salesRep1CommissionPercent",
  "salesRep2commissionPercent",
  "creditLimit",
  "creditCardProcessingFeePercentage",
  "localTax",
  "stateTax",
  "federalTax",
  "lineItemDiscountPercentage",
  "perUnitLineItemDiscountAmount",
  "netDiscountPercentage",
  "discretionaryDiscountPercentage",
  "additionalFreightPercentage",
  "additionalFreightAmount",
  "currentAR",
  "currentARTC",
  "age30",
  "age30TC",
  "age60",
  "age60TC",
  "age90",
  "age90TC",
  "age120",
  "age120TC",
  "totalAR",
  "totalARTC",
  "creditAvail",
  "creditAvailTC",
  "rowVersion",
  "additionalFreightAmount",
  "additionalFreightPercentage",
  "creditAvail",
  "creditCardProcessingFeePercentage",
  "creditLimit",
  "currentAR",
  "currentARTC",
  "netDiscountPercentage",
  "localTax",
  "lineItemDiscountPercentage",
  "federalTax",
  "fax",
  "discretionaryDiscountPercentage",
  "salesRep1CommissionPercent",
  "resale",
  "priority",
  "perUnitLineItemDiscountAmount",
  "stateTax",
  "salesRep2commissionPercent",
  "totalAR",
  "totalARTC",

]


export const orderGridHeaders = {
  "orderID": "Order ID",
  "order": "Order",
  "status": "Status",
  "retailOrder": "Retail Order",
  "bulkOrder": "Bulk Order",
  "distributionOrder": "Distribution Order",
  "entered": "Entered",
  "start": "Start",
  "complete": "Complete",
  "thirdPartyOrderCreateDate": "Third Party Order Create Date",
  "cancelledDate": "Cancelled Date",
  "customerID": "Customer ID",
  "customerAccount": "Customer Account",
  "customerName": "Customer Name",
  "currencyID": "Currency ID",
  "currency": "Currency",
  "currencyName": "Currency Name",
  "currencySymbol": "Currency Symbol",
  "currencyCode" :"Currency Code",
  "exchangeRateDate": "Exchange Rate Date",
  "exchangeRate": "Exchange Rate",
  "orderStatusReasonID": "Order Status Reason ID",
  "orderStatusReasonCode": "Order Status Reason Code",
  "orderStatusReasonDescription": "Order Status Reason Description",
  "originalBookedTotal": "Original Booked Total",
  "bookedTotal": "Booked Qty",
  "cancelledTotal": "Cancelled Qty",
  "shippedTotal": "Shipped Qty",
  "openTotal": "Open Qty",
  "allocatedStockTotal": "Allocated Stock Total",
  "allocatedWipTotal": "Allocated WIP Total",
  "allocatedTotal": "Allocated Total",
  "originalBookedAmount": "Original Booked Amount",
  "originalBookedAmount_tc": "Original Booked Amount TC",
  "bookedAmount": "Booked Amt",
  "bookedAmount_tc": "Booked Amount TC",
  "cancelledAmount": "Cancelled Amount",
  "cancelledAmount_tc": "Cancelled Amount TC",
  "shippedAmount": "Shipped Amt",
  "shippedAmount_tc": "Shipped Amount TC",
  "openAmount": "Open Amt",
  "openAmount_tc": "Open Amount TC",
  "pickAmount": "Pick Amount",
  "pickAmount_tc": "Pick Amount TC",
  "prepaymentBalance": "Prepayment Balance",
  "prepaymentBalance_tc": "Prepayment Balance TC",
  "preAuthorizationBalance": "Preauthorization Balance",
  "preAuthorizationBalance_tc": "Preauthorization Balance TC",
  "billToStoreID": "Bill To Store ID",
  "billToStoreCode": "Bill To Store Code",
  "billToStoreName": "Bill To Store Name",
  "billToAddress1": "Bill To Address1",
  "billToAddress2": "Bill To Address2",
  "billToAddress3": "Bill To Address3",
  "billToCity": "Bill To City",
  "billToState": "Bill To State",
  "billToZip": "Bill To Zip",
  "billToCountryID": "Bill To Country ID",
  "billToCountryCode": "Bill To Country Code",
  "billToCountryName": "Bill To Country Name",
  "shipToStoreID": "Ship To Store ID",
  "shipToStoreCode": "Ship To Store Code",
  "shipToStoreName": "Ship To Store Name",
  "shipToAddress1": "Ship To Address1",
  "shipToAddress2": "Ship To Address2",
  "shipToAddress3": "Ship To Address3",
  "shipToCity": "Ship To City",
  "shipToState": "Ship To State",
  "shipToZip": "Ship To Zip",
  "shipToCountryID": "Ship To Country ID",
  "shipToCountryCode": "Ship To Country Code",
  "shipToCountryName": "Ship To Country Name",
  "shipToFreeFormAddress": "Ship To Free Form Address",
  "distributionCenterID": "Distribution Center ID",
  "distributionCenterCode": "Distribution Center Code",
  "distributionCenterName": "Distribution Center Name",
  "customerPurchaseOrder": "Customer Purchase Order",
  "buyer": "Buyer",
  "phone": "Phone",
  "fax": "Fax",
  "email": "Email",
  "note1": "Note1",
  "note2": "Note2",
  "priority": "Priority",
  "department": "Department",
  "lineItemDiscountType": "Line Item Discount Type",
  "netDiscountPercentage": "Net Discount Percentage",
  "discretionaryDiscountReasonID": "Discretionary Discount Reason ID",
  "discretionaryDiscountReasonCode": "Discretionary Discount Reason Code",
  "discretionaryDiscountReasonDescription": "Discretionary Discount Reason Description",
  "discretionaryDiscountType": "Discretionary Discount Type",
  "discretionaryDiscountPercentage": "Discretionary Discount Percentage",
  "discretionaryDiscountAmount": "Discretionary Discount Amount",
  "discretionaryDiscountAmount_tc": "Discretionary Discount Amount TC",
  "originalDiscretionaryDiscountAmount": "Original Discretionary Discount Amount",
  "originalDiscretionaryDiscountAmount_tc": "Original Discretionary Discount Amount TC",
  "dropShipOrder": "Drop Ship Order",
  "residentialAddress": "Residential Address",
  "shipComplete": "Ship Complete",
  "seasonID": "Season ID",
  "seasonCode": "Season Code",
  "seasonName": "Season Name",
  "divisionID": "Division ID",
  "divisionCode": "Division Code",
  "divisionName": "Division Name",
  "divisionAbbreviation": "Division Abbreviation",
  "termID": "Term ID",
  "termCode": "Term Code",
  "termDescription": "Term Description",
  "shipviaID": "Shipvia ID",
  "shipviaCode": "Shipvia Code",
  "shipviaDescription": "Shipvia Description",
  "specialInstructionID": "Special Instruction ID",
  "userCodeID": "User Code ID",
  "vendor": "Vendor",
  "packingRuleType": "Packing Rule Type",
  "freightCollect": "Freight Collect",
  "cod": "COD",
  "salesRep1ID": "Sales Rep1 ID",
  "salesRep1Code": "Sales Rep1 Code",
  "salesRep1Name": "Sales Rep1 Name",
  "salesRep1Points": "Sales Rep1 Points",
  "salesRep1Ratio": "Sales Rep1 Ratio",
  "salesRep1ExchangeRate": "Sales Rep1 Exchange Rate",
  "salesRep1ExchangeRateDate": "Sales Rep1 Exchange Rate Date",
  "salesRep1CurrencyID": "Sales Rep1 Currency ID",
  "salesRep1CurrencyCode": "Sales Rep1 Currency Code",
  "salesRep1CurrencyName": "Sales Rep1 Currency Name",
  "salesRep1CurrencySymbol": "Sales Rep1 Currency Symbol",
  "salesRep2ID": "Sales Rep2 ID",
  "salesRep2Code": "Sales Rep2 Code",
  "salesRep2Name": "Sales Rep2 Name",
  "salesRep2Points": "Sales Rep2 Points",
  "salesRep2Ratio": "Sales Rep2 Ratio",
  "salesRep2ExchangeRate": "Sales Rep2 Exchange Rate",
  "salesRep2ExchangeRateDate": "Sales Rep2 Exchange Rate Date",
  "salesRep2CurrencyID": "Sales Rep2 Currency ID",
  "salesRep2CurrencyCode": "Sales Rep2 Currency Code",
  "salesRep2CurrencyName": "Sales Rep2 Currency Name",
  "salesRep2CurrencySymbol": "Sales Rep2 Currency Symbol",
  "factorID": "Factor ID",
  "factorAccount": "Factor Account",
  "factorName": "Factor Name",
  "factorNotes": "Factor Notes",
  "creditDecisionDate": "Credit Decision Date",
  "creditDecisionAmount": "Credit Decision Amount",
  "creditDecisionComment": "Credit Decision Comment",
  "creditApprovalBatch": "Credit Approval Batch",
  "notes": "Notes",
  "customerNotes": "Customer Notes",
  "shippingInstructions": "Shipping Instructions",
  "packingNotes": "Packing Notes",
  "valueAddedServiceNotes": "Value Added Service Notes",
  "cardType": "Card Type",
  "firstName": "First Name",
  "lastName": "Last Name",
  "cardBillingAddress": "Card Billing Address",
  "cardBillingZip": "Card Billing Zip",
  "expirationMonth": "Expiration Month",
  "expirationYear": "Expiration Year",
  "cardAmount": "Card Amount",
  "cardAmount_tc": "Card Amount TC",
  "globalLocationNumber": "Global Location Number",
  "promoDeal": "Promo Deal",
  "merchandiseType": "Merchandise Type",
  "releasePO": "Release PO",
  "ediBatchnumber": "EDI Batch Number",
  "ediAllowanceCode1": "EDI Allowance Code1",
  "ediAllowanceAmount1": "EDI Allowance Amount1",
  "ediAllowanceAmount1_tc": "EDI Allowance Amount1 TC",
  "ediAllowancePercentage1": "EDI Allowance Percentage1",
  "ediAllowanceCode2": "EDI Allowance Code2",
  "ediAllowanceAmount2": "EDI Allowance Amount2",
  "ediAllowanceAmount2_tc": "EDI Allowance Amount2 TC",
  "ediAllowancePercentage2": "EDI Allowance Percentage2",
  "ediAllowanceCode3": "EDI Allowance Code3",
  "ediAllowanceAmount3": "EDI Allowance Amount3",
  "ediAllowanceAmount3_tc": "EDI Allowance Amount3 TC",
  "ediAllowancePercentage3": "EDI Allowance Percentage3",
  "ediAllowanceCode4": "EDI Allowance Code4",
  "ediAllowanceAmount4": "EDI Allowance Amount4",
  "ediAllowanceAmount4_tc": "EDI Allowance Amount4 TC",
  "ediAllowancePercentage4": "EDI Allowance Percentage4",
  "ediAllowanceCode5": "EDI Allowance Code5",
  "ediAllowanceAmount5": "EDI Allowance Amount5",
  "ediAllowanceAmount5_tc": "EDI Allowance Amount5 TC",
  "ediAllowancePercentage5": "EDI Allowance Percentage5",
  "ediAllowanceCode6": "EDI Allowance Code6",
  "ediAllowanceAmount6": "EDI Allowance Amount6",
  "ediAllowanceAmount6_tc": "EDI Allowance Amount6 TC",
  "ediAllowancePercentage6": "EDI Allowance Percentage6",
  "ediAllowanceCode7": "EDI Allowance Code7",
  "ediAllowanceAmount7": "EDI Allowance Amount7",
  "ediAllowanceAmount7_tc": "EDI Allowance Amount7 TC",
  "ediAllowancePercentage7": "EDI Allowance Percentage7",
  "thirdPartyOrderID": "Third Party Order ID",
  "giftAmount": "Gift Amount",
  "giftAmount_tc": "Gift Amount TC",
  "freightType": "Freight Type",
  "freightAmount": "Freight Amount",
  "freightAmount_tc": "Freight Amount TC",
  "freightUsedAmount": "Freight Used Amount",
  "freightUsedAmount_tc": "Freight Used Amount TC",
  "giftCardOriginalAmount": "Gift Card Original Amount",
  "giftCardOriginalAmount_tc": "Gift Card Original Amount TC",
  "createdOn": "Created On",
  "lastModified": "Last Modified",
  "createdByOperatorID": "Created By Operator ID",
  "createdByFirstName": "Created By First Name",
  "createdByLastName": "Created By Last Name",
  "createdByEmailAddress": "Created By Email Address",
  "modifiedByOperatorID": "Modified By Operator ID",
  "modifiedByFirstName": "Modified By First Name",
  "modifiedByLastName": "Modified By Last Name",
  "modifiedByEmailAddress": "Modified By Email Address",
  "rowVersion": "Row Version",
  "orderSource@odata.type": "Order Source OData Type",
  "orderSource": "Order Source",
  "billToFreeFormAddress": "Bill To Free Form Address",
  "specialInstructionCode": "Special Instruction Code",
  "specialInstructionDescription": "Special Instruction Description",
  "userCode": "User Code",
  "userCodeDescription": "User Code Description",
}


export const orderNumberKeys = [
  "orderID",
  "customerID",
  "currencyID",
  "exchangeRate",
  "orderStatusReasonID",
  "originalBookedTotal",
  "bookedTotal",
  "cancelledTotal",
  "shippedTotal",
  "openTotal",
  "allocatedStockTotal",
  "allocatedWipTotal",
  "allocatedTotal",
  "originalBookedAmount",
  "originalBookedAmount_tc",
  "bookedAmount",
  "bookedAmount_tc",
  "cancelledAmount",
  "cancelledAmount_tc",
  "shippedAmount",
  "shippedAmount_tc",
  "openAmount",
  "openAmount_tc",
  "pickAmount",
  "pickAmount_tc",
  "prepaymentBalance",
  "prepaymentBalance_tc",
  "preAuthorizationBalance",
  "preAuthorizationBalance_tc",
  "billToStoreID",
  "billToCountryID",
  "shipToStoreID",
  "shipToCountryID",
  "distributionCenterID",
  "priority",
  "netDiscountPercentage",
  "discretionaryDiscountReasonID",
  "discretionaryDiscountPercentage",
  "discretionaryDiscountAmount",
  "discretionaryDiscountAmount_tc",
  "originalDiscretionaryDiscountAmount",
  "originalDiscretionaryDiscountAmount_tc",
  "seasonID",
  "divisionID",
  "termID",
  "shipviaID",
  "specialInstructionID",
  "userCodeID",
  "salesRep1ID",
  "salesRep1Points",
  "salesRep1Ratio",
  "salesRep1ExchangeRate",
  "salesRep1CurrencyID",
  "salesRep2ID",
  "salesRep2Ratio",
  "salesRep2Points",
  "salesRep2ExchangeRate",
  "salesRep2CurrencyID",
  "factorID",
  "creditDecisionAmount",
  "creditDecisionComment",
  "expirationMonth",
  "expirationYear",
  "cardAmount",
  "cardAmount_tc",
  "ediAllowanceAmount1",
  "ediAllowanceAmount1_tc",
  "ediAllowancePercentage1",
  "ediAllowanceAmount2",
  "ediAllowanceAmount2_tc",
  "ediAllowancePercentage2",
  "ediAllowanceAmount3",
  "ediAllowanceAmount3_tc",
  "ediAllowancePercentage3",
  "ediAllowanceAmount4",
  "ediAllowanceAmount4_tc",
  "ediAllowancePercentage4",
  "ediAllowanceAmount5",
  "ediAllowanceAmount5_tc",
  "ediAllowancePercentage5",
  "ediAllowanceAmount6",
  "ediAllowanceAmount6_tc",
  "ediAllowancePercentage6",
  "ediAllowanceAmount7",
  "ediAllowanceAmount7_tc",
  "ediAllowancePercentage7",
  "giftAmount",
  "giftAmount_tc",
  "freightAmount",
  "freightAmount_tc",
  "freightUsedAmount",
  "freightUsedAmount_tc",
  "giftCardOriginalAmount",
  "giftCardOriginalAmount_tc",
  "createdByOperatorID",
  "modifiedByOperatorID"
]

export const CustomFieldBulkDataHeaders = {
  "displayName" : "Field Name"
}



export const ordersDetails = {
  BatchId:"Batch Id",
  CustomFields:"Custom Fields",
  CustomerAccount:"Customer Account",
  CustomerName:"Customer Name",
  Error:"Error",
  JobId:"Job Id",
  OrderId:"Order Id",
  OrderNumber:"Order",
  RecordId:"Record Id",
  Response:"Response",
  Status:"Status",
  UpdatedBy:"Updated By",
  UpdatedOn:"Updated On",
}



export const AquaScheduler={
  id:"Job Id",
  scheduledJobID:"Scheduled Job ID",
  scheduledJobName:"Job Name",
  startDateTime:"Start Date",
  pubLink:"Publishing URL",
  scheduledJobCategoryID:"Scheduled Job Category ID",
  scheduledJobCategoryName:"Scheduled Job CategoryName",
  scheduledJobSubCategoryID:"SchSeduled Job SubCategory ID",
  scheduledJobSubCategoryName:"Scheduled Job SubCategory Name",
  description:"Job Description",
  Active: "Active",
  recurrenceType: "Recurrence Type",
  status:"Job Status",
  createdDateTime:"Created Date",
  modifiedDateTime:"Modified DateTime",
  endBy:"End By",
  createdBy:"Created By",
  modifiedBy: "Modified By",
  clientID:"Client ID",
  apiUserEmailAddress:"Api User Email Address",
  rowversion:"Row Version",
  recurrence:"Recurrence",
  scheduledJobAction:"Scheduled Job Action",
  uniqueId:"Unique ID"

}

export const AquaSchedulerKeys=[
  "scheduledJobID",
  "scheduledJobCategoryID",
  "scheduledJobSubCategoryID",
  
]

export const AquaSchedulerJobHistory={
  backgroundJobRunHistoryID:"History Id",
  backgroundJobID:"Background Job ID",
  jobID:"Job ID",
  clientRequestId:"Client Request Id",
  clientID:"Client ID",
  jobStatus:"Job Status",
  exceptionID:"Exception ID",
  jobStatusText:"Job Status",
  response:"Response Type",
  purgeDateTime:"Purge Date",
  accountCode:"Account Code",
  jobRunID:"Job Run ID",
  databaseName:"Database name",
  jobStatusID:"Job Status ID",
  percentCompleted:"Percent Completed",
  startDate:"Start Date",
  completedDate:"Completed Date",
  createdBy:"Created By",
  modifiedBy:"Modified By",
  createdDate:"Created Date",
  modifiedDate : "Modified Date",
  rowVersion:"Row Version",
}

export const InquiryNames={
  allocationQuantity:"Alloc",
  binLocation:"BinLocation",
  binLocationCategory:"BIN Category",
  color:"Color",
  inTransferQuantity:"InXfer",
  openStockQuantity:"Available",
  pickQuantity:"PKT",
  sizeName:"Size",
  sizePosition:"Size Position",
  stockQuantity:"Stock",
  style:"Style",
  styleColorDefaultImageURL:"Style Color Default ImageURL",
  styleDefaultImageURL:"Img"
}

export const InquriyNumericKeys=[
  "allocationQuantity",
  "inTransferQuantity",
  "openStockQuantity",
  "pickQuantity",
  "sizePosition",
  "stockQuantity"
]

export const importStatusNumberKeys = [
  "Success",
  "Failure"
]

export const warehouseTransferSettingsHeaders ={ 
  "warehouseID": "Warehouse ID",
  "warehouseCode": "Warehouse Code",
  "warehouseName": "Warehouse Name",
  "status": "Status",
  VerifyAllowUserToScanDifferentBinlocation:"Allow Change Bin Location On Pick Ticket",
  VerifyBin:"Scan To Verify Bin Location",
  VerifyLine:"Scan To Verify Line Item",
}

export const SyncDataNumberKeys = [
  "RowVersion"
]

export const syncDataHeaderMappins={
  PkMondayComSyncedDataDetails: "Pk Mapping",
  "GuidMondayComSyncedDataDetails": "Key Guid",
  "PurchaseOrderId": "Purchase Order ID",
  "PurchaseOrderNumber": "Po No",
  "VendorCode": "Vendor Code",
  "Style": "Style",
  "Color": "Color",
  "CustomerAllocatedOrderId": "Customer Allocated Order ID",
  "PurchaseOrderLineId": "Purchase Order Line ID",
  "StyleColorId": "Style Color ID",
  "JobId": "Job ID",
  "Status": "Status",
  "Message": "Message",
  "MondaycomId": "Mondaycom ID",
  "RowVersion": "Row Version",
  "JobTriggerTime": "Job Trigger Time",
  "ProcessedTime": "Processed Time",
  "OrderLineId": "Order Line ID",
  "CustomerName": "Customer Name",
  "VendorPostatus": "Vendor PO Status",
  "CustomerOrderStatus": "Customer Order Status",
  "ArchiveStatus": "Archive Status",
  "ArchivemondaycomId": "Archive Mondaycom ID",
  "OrderDate": "Order Date",
  "CustomerOrderNumber": "Customer Order Number",
  "CustomerOrderId": "Customer Order ID"
}

export const importDetailsRmaHeaders = {
  RecordId: "Record ID",
  Customer: "Customer",
  ReturnReason: "Return Reason",
  Division: "Division",
  StockReceiveWarehouse: "Return Warehouse",
  CustomerPo: "Customer PO",
  Reference: "Reference",
  AuthorizeBy: "Authorized By",
  Invoice: "Invoice",
  Upc: "UPC",
  Style: "Style",
  Color: "Color",
  Size: "Size",
  AuthorizeQty: "Authorize QTY",
  AuthorizePrice: "Authorize Price",
  ValidationStatus: "Validation Status",
  ProcessStatus: "Process Status",
  FileName: "File Name",
  TriggerTime: "Entered Date",
  JobId: "Job ID",
  Error: "Error",
  CreatedBy: "Created By",
  ImportType: "Import Type",
  ImportJobId: "Import Job ID",
  BatchId: "Batch ID",
  PublishLink: "Publish Link",
  Rmaid: "RMA ID",
  RmaNo: "RMA No",
  CustomerId: "Customer ID",
  ReturnReasonId: "Return Reason ID",
  DivisionId: "Division ID",
  StockWareHouseId: "Stock Warehouse ID",
  StyleColorId: "Style Color ID",
  ExportedRecord: "Exported Record"
}

export const importDetailsStyleImageHeaders = {
  RecordId: "Record ID",
  Style: "Style",
  Color: "Color",
  ImageDescription: "Image Description",
  ImageType: "Image Type",
  ImageSequence: "Image Sequence",
  ImageUrl: "Image URL",
  BatchId: "Batch ID",
  JobId: "Job ID",
  ImportJobId: "Import Job ID",
  TriggerTime: "Triggered Time",
  ValidationStatus: "Validation Status",
  ProcessStatus: "Process Status",
  FileName: "File Name",
  Error: "Error",
  ImportType: "Import Type",
  CreatedBy: "Created By",
  Weblink: "Weblink",
  Remotelink: "Remotelink",
  Shopify: "Shopify",
  Joor: "Joor",
  Nuorder: "Nuorder",
  Brandboom: "Brandboom",
  Easyshop: "Easyshop",
  PublishLink: "Publish Link",
  JoorImageType: "Joor Image Type",
}

export const aquaJobHistoryNumberKeys=[
  "BackgroundJobRunHistoryID",
  "PercentCompleted",
  "ClientRequestId"
]


export const aquaJobHistoryHeaderNames={
  
  "BackgroundJobRunHistoryID": "History ID",
  "JobID":  "Job ID",
  "ClientRequestId": "Client Request ID",
  "ClientID": "Client ID",
  "JobStatus": "Job Status",
  "JobStatusText": "Job Status Text",
  "Response": "Response Type",
  "PurgeDateTime": "Purge Date",
  "AccountCode": "Account Code",
  "JobRunID": "Job Run ID",
  "DatabaseName": "Database Name",
  "PercentCompleted": "Percent Completed",
  "StartDate": "Start Date",
  "CompletedDate": "Completed Date",
  "CreatedBy": "Created By",
  "ModifiedBy": "Modified By",
  "CreatedDate": "Created Date",
  "ModifiedDate": "Modified Date",

}

export const importDetailsWHTransferHeaders = {
  RecordId: "Record ID",
  TransferWhCode: "Transferring Warehouse Code",
  TransferWhName: "Transfer Warehouse Name",
  Style: "Style",
  Color: "Color",
  Size: "Size",
  Upc: "UPC",
  BinLocations: "BIN Location",
  DestinationWhCode: "Destination Warehouse Code",
  DestinationWhName: "Destination Warehouse Name",
  TrnasferQty: "Transfer Quantity",
  TrackNumber: "Tracking Number",
  Reference: "Reference",
  ShipVia: "Ship Via",
  Status: "Status",
  ValidationStatus: "Validation Status",
  ProcessStatus: "Process Status",
  FileName: "File Name",
  TriggerTime: "Trigger Time",
  JobId: "Job ID",
  Error: "Error",
  CreatedBy: "Created By",
  ImportType: "Import Type",
  ImportJobId: "Import Job ID",
  BatchId: "Batch ID",
  PublishLink: "Publish Link"
}

