const Exception =(data)=>{
  const {
    clientId,
    guid,
    account,
    category,
    e,
    machineId,
    type,
    url,
    body,
    timeZoneStandardName,
    ip
  } = data;
const GetExceptionMessage = ()=>{

  if (e?.response?.data?.aims360Error?.message || e?.response?.data?.Aims360Error?.Message) {
    return e.response?.data?.aims360Error?.message || e?.response?.data?.Aims360Error?.Message;
  } else if (e?.response?.data?.runwayError?.message || e?.response?.data?.RunwayError?.Message) {
    return e?.response?.data?.runwayError?.message || e?.response?.data?.RunwayError?.Message;
  } else if (e?.message) {
    return e.message;
  } else {
    return "Fetched Successfully";
  }

}
function GetSourceClass(inputString, substringToRemove) {
  // Check if the substring is present in the string
  if (inputString.includes(substringToRemove)) {
      // Remove the substring from the string
      inputString = inputString.replace(new RegExp(substringToRemove, 'g'), "");
  }

  return inputString;
}
   let payload ={ 
        "clientID": clientId,
        "requestId":guid,
        "customerAccount": account,
        "Environment": process.env.REACT_APP_ENV === "dev" ? "Test" : "Production",
        "Version": "V1.0",
        "Category": category,
        "HResult": "0x800A03EC",
        "InnerException": "Inner Exception",
        "ExceptionMessage": GetExceptionMessage(),
        "InnerExceptionMessage": "Inner Exception Message",
        "ExceptionType": "System.AggregateException",
        "SourceClass": GetSourceClass(category, "AIMS360"),
        "StackTrace": e?.message ? e?.message :"Fetched Successfully",
        "RequestURL":e?.config?.url ? e?.config?.url : url,
        "RequestType": type,
        "LogType":"Exception",
        "Requestbody":type !== "GET" ? JSON.stringify(body) : "",
        "ThreadName": "3415",
        "IPAddress": ip,
        "MachineName": ip,
        "Referece1": "",
        "Reference2": "",
        "Reference3": "",
        "Reference4": "",
        "Reference5": "",
        "MachineMacID":machineId,
        "timeZoneStandardName": timeZoneStandardName,
      }
      return payload;
        
}
export {Exception}