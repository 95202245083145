/**
 * Sorts an array of items based on type, favorite status, and name.
 * @param {Array} items - The array of items to sort.
 * @param {Object} options - Sorting options.
 * @param {boolean} options.systemFirst - If true, system items come first. If false, favorites come first.
 * @returns {Array} The sorted array.
 */
export const sortItems = (items, options = {}) => {
  const { systemFirst = true } = options;

  return items.sort((a, b) => {
    // Parse item name
    const parseItem = (name) => {
      const match = name.match(/^(.+?)\s*(?:\((\d+)\))?$/);
      return match ?
        { base: match[1].trim(), num: match[2] ? parseInt(match[2], 10) : Infinity } :
        { base: name.trim(), num: Infinity };
    };

    const itemA = parseItem(a.name);
    const itemB = parseItem(b.name);

    // Compare based on priority (system or favorite)
    if (systemFirst) {
      // System first, then favorites
      if (a.type.toLowerCase() === 'system' && b.type.toLowerCase() !== 'system') return -1;
      if (a.type.toLowerCase() !== 'system' && b.type.toLowerCase() === 'system') return 1;
      if (a.type.toLowerCase() === b.type.toLowerCase()) {
        if (a.isFavorite && !b.isFavorite) return -1;
        if (!a.isFavorite && b.isFavorite) return 1;
      }
    } else {
      // Favorites first, then system
      if (a.isFavorite && !b.isFavorite) return -1;
      if (!a.isFavorite && b.isFavorite) return 1;
      if (a.isFavorite === b.isFavorite) {
        if (a.type.toLowerCase() === 'system' && b.type.toLowerCase() !== 'system') return -1;
        if (a.type.toLowerCase() !== 'system' && b.type.toLowerCase() === 'system') return 1;
      }
    }

    // Compare base names (case-insensitive)
    if (itemA.base.toLowerCase() < itemB.base.toLowerCase()) return -1;
    if (itemA.base.toLowerCase() > itemB.base.toLowerCase()) return 1;

    // If base names are the same, compare numbers
    return itemA.num - itemB.num;
  });
};