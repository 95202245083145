/**
 * @endpoint - only environment varible without zone (ex: 'REACT_APP_MODAY_SYNC')
 * @returns url (return the url with zone)
 */
const getURLByZone = (endpoint,zone) => {
  let url = "";
  if (zone?.toLowerCase() === "east") {                                                      
    url = endpoint + "_EAST";
  } else {
    url = endpoint + "_WEST";
  }

  url = `${process.env[url]}`;
  return url;
};


export { getURLByZone };
