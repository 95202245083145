/* eslint-disable react-hooks/exhaustive-deps */
import {
  CInput,
  CListGroup,
  CListGroupItem,
  CNav,
  CNavItem,
  CNavLink,
  CSidebarNavItem,
  CTabContent,
  CTabPane,
  CTabs,
  CLabel,
  CCard,
  CButton,
  CCol,
  CRow,
  CLink,
  CTooltip,
} from "@coreui/react";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import AReactSelect from "../../../Components/AReactSelect";
import AMultiSelect from "../../../Components/AMultiSelect";
import { useSelector } from "react-redux";
import _ from "lodash";
import handleErrorMessage from "../../../helpers/handleErrorMessage";
import AButton from "../../../Components/AButton";
import { readPermissionMessage } from "../../../Services/UserAccessPermission";

const GetOperatorHtml = (expressionId, expressionValue, OnOperatorSelected, needToShowError) => {
  return (
    <>
      <CLabel
        className="operator-display"
        onClick={() =>
          OnOperatorSelected(expressionId, expressionValue, "operator", needToShowError)
        }
      >
        {expressionValue}
      </CLabel>
    </>
  );
};

const GetConditionalHtml = (expressionId, expressionValue, OnOperatorSelected, needToShowError) => {
  return (
    <>
      <CLabel
        className="operator-display"
        onClick={() =>
          OnOperatorSelected(expressionId, expressionValue, "condition", needToShowError)
        }
      >
        {expressionValue}
      </CLabel>
    </>
  );
};


const GetQueryString = (queryBuilder, OnOperatorSelected, GetClassNameForGroup, OnGroupSelected, GetClassNameForexpression, OnExpressionSelected, HeaderKeyMappings, filterQueryBuilder, shouldDisplayExpression, mapExpressionValue, onClickedExpression) => {

  const filteredQueryBuilder = filterQueryBuilder(queryBuilder);
  return (
    <>
      {filteredQueryBuilder.length > 0 && filteredQueryBuilder.map((exp, index) => (
        <React.Fragment key={exp.id}>
          {exp.type === "Condition" && (
            <CLabel style={{ marginLeft: "5px" }}>
              {GetConditionalHtml(exp.id, exp.condition, OnOperatorSelected, exp?.isReadable)}
            </CLabel>
          )}
          {exp.type === "Group" && (
            <CLabel
              className={GetClassNameForGroup(exp)}
              onClick={() => OnGroupSelected(exp)}
              style={{
                marginLeft: "2px",
                fontSize: "20px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              {exp.value}
            </CLabel>
          )}
          {exp.type === "Expression" && shouldDisplayExpression(exp) && (
            <CLabel
              style={{ marginLeft: "5px" }}
              className={GetClassNameForexpression(exp)}
              onClick={() => {onClickedExpression(exp)}}
            >
              <CLabel
                style={{ marginBottom: "0px" }}
                onClick={() => OnExpressionSelected(exp)}
              >
                {exp?.header || HeaderKeyMappings[exp.key] || exp.key}
              </CLabel>
              {GetOperatorHtml(exp.id, exp.operator, OnOperatorSelected, exp?.isReadable)}
              <CLabel
                style={{ marginBottom: "0px" }}
                onClick={() => OnExpressionSelected(exp)}
              >
                {mapExpressionValue(exp.value)}
              </CLabel>
            </CLabel>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

function FilteredColumns({
  getUpdatedFilters,
  getUpdatedColumns,
  activeView,
  updateSidebar,
  updateFilterCheck,
  views,
  gridData,
  excludedKeys,
  primaryKeys,
  toggleActionButtonsForAll,
  setFilterButtonData,
  filterButtonData,
  setIsViewResetToOriginal,
  isViewResetToOriginal,
  HeaderKeyMappings,
  setIsSystemView,
  setSystemViewMessage,
  DynamicFilters=[],
  excludeFilterColumnKeys="",
  gridColumns=[],
  filterOperatorsNumberKeysOnly=[],
  previewView=null,
}) {
  const [selectedFilterColumn, setSelectedFilterColumn] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("");
  const [selectedOperation, setSelectedOperation] = useState("");
  const [enteredValue, setEnteredValue] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("");
  const [columnOptions, setColumnOptions] = useState([]);
  const [currentOperation, setCurrentOperation] = useState();
  const [nextActions, setNextActions] = useState([]);
  const [selectedToggleExpression, setSelectedToggleExpression] = useState({});
  const [selectedExpressions, setSelectedExpressions] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedTab, setSelectedTab] = useState([]);
  const [disableAdd, setDisableAdd] = useState(false);
  const [isShowCondition, setShowCondition] = useState(false);
  const initialQuiryBuilder = [
    {
      id: getRandomAlphaNumericString(10),
      key: "DefaultLeftKey",
      value: "(",
      operator: "",
      condition: "",
      isExpSelected: false,
      type: "Group",
    },
    {
      id: getRandomAlphaNumericString(10),
      key: "DefaultRightKey",
      value: ")",
      operator: "",
      condition: "",
      isExpSelected: false,
      type: "Group",
    },
  ];
  const [queryBuilder, SetQueryBuilder] = useState(initialQuiryBuilder);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [initSlectedColumns, setInitSelectedColumns] = useState([]);
  const [initFilterQuery, setInitFilterQuery] = useState([]);
  const [columnButton, setColumnButton] = useState("");
  const [filterButton, setFilterButton] = useState("");
  const [loadingButton, setLoadingButton] = useState({ tab: null, button: null });
  const [filterSelectedColumns, setFilterSelectedColumns] = useState([]);
  const [clickedExpression, setClickedExpression] = useState({});
  const darkMode = useSelector((state) => state.darkMode);
  const tabLinks = [
    { label: "Filters", value: "filters" },
    { label: "Columns", value: "columns" },
  ];

  const oDataConditions = {
    common: [
      { label: '= (Equal to)', value: 'eq' },
      { label: '!= (Not equal to)', value: 'ne' },
    ],
    number_date: [
      { label: '>= (Greater than or equal to)', value: 'ge' },
      { label: '<= (Less than or equal to)', value: 'le' },
      { label: '> (Greater than)', value: 'gt' },
      { label: '< (Less than)', value: 'lt' },
    ],
    string: [
      { label: 'contains', value: 'contains' },
      { label: 'startswith', value: 'startswith' },
      { label: 'endswith', value: 'endswith' },
      // { label: 'substringof', value: 'substringof' },
    ],
  };

  const separateConditions = [
    { label: "and", value: "and" },
    { label: "or", value: "or" },
    // { label: "in", value: "in" },
  ];

  useEffect(() => {
    GetColumnsFromView(previewView?.isColumn ? previewView : activeView);
    UpdateFiltersFromView(activeView);
    toggleActionButtons(activeView);
  }, [activeView, gridData, previewView]);

  useEffect(() => {
    if (isViewResetToOriginal) {
      SetQueryBuilder(activeView.filters !== "[]" && activeView.filters ? JSON.parse(activeView.filters) : initialQuiryBuilder);
      setInitFilterQuery(activeView.filters !== "[]" && activeView.filters ? JSON.parse(activeView.filters) : initialQuiryBuilder);
      setIsViewResetToOriginal(false);
      setFilterButton("");
      setColumnButton("");
    }
  }, [isViewResetToOriginal])

  const mapExpressionValue = (value) => {
    const match = _.find(DynamicFilters, { label: value });
    return match ? match.value : value;
  };
  
  const shouldDisplayExpression = (exp) => {
    const mappedValue = mapExpressionValue(exp.value);
    return mappedValue !== undefined && mappedValue !== '';
  };
  
  const filterQueryBuilder = (queries) => {
    // Preserve the outermost parentheses
    const outerStart = queries[0];
    const outerEnd = queries[queries.length - 1];
    const innerQueries = queries.slice(1, -1);
    const filteredInner = innerQueries.reduce((acc, exp, index, array) => {
      if (exp.type === "Expression") {
        if (shouldDisplayExpression(exp)) {
          acc.push(exp);
        } else {
          // If this expression should not be displayed, remove only the preceding "and" if it exists
          if (index > 0 && array[index - 1].type === "Condition" && (array[index - 1].condition.toLowerCase() === "and" || array[index - 1].condition.toLowerCase() === "or")) {
            acc.pop();
          }
          // Check for and remove empty parentheses
          if (index > 0 && index < array.length - 1) {
            const prev = array[index - 1];
            const next = array[index + 1];
            if (prev.type === "Group" && prev.value === "(" && next.type === "Group" && next.value === ")") {
              acc.pop(); // Remove the opening parenthesis
              // The closing parenthesis will be skipped in the next iteration
            }
          }
        }
      } else {
        acc.push(exp);
      }
      return acc;
    }, []);
  
    // Reconstruct the query with preserved outermost parentheses
    return [outerStart, ...filteredInner, outerEnd].filter(exp => 
      exp.type !== "Condition" || (filteredInner.length > 0 && exp !== outerEnd)
    );
  };

    // Memoize the filterQueryBuilder result
    const filteredQueryBuilder = useMemo(() => {
      return filterQueryBuilder(queryBuilder);
    }, [queryBuilder]);

    // Use useEffect to update isShowCondition based on filteredQueryBuilder
    useEffect(() => {
      setShowCondition(filteredQueryBuilder.length > 2);
    }, [filteredQueryBuilder]);

  const toggleActionButtons = (activeViewInfo) => {
    const actionButtons = filterButtonData;
    if (activeViewInfo.type === "System") {
      const saveButtonIndex = actionButtons.findIndex(
        (button) => button.value === "save"
      );
      if (saveButtonIndex !== -1) {
        actionButtons[saveButtonIndex].disable = true;
      }
    }
    setFilterButtonData(actionButtons);
  };

  function hasChanged(originalArray, modifiedArray) {
    // Check if the length has changed (new objects added)
    if (originalArray.length !== modifiedArray.length) {
      return false;
    }
  
    // Check if any object has changed position
    for (let i = 0; i < originalArray.length; i++) {
      if (!_.isEqual(originalArray[i], modifiedArray[i])) {
        return false;
      }
    }
    // If no changes detected
    return true;
  }

  const GetColumnsFromView = (activatedView) => {
    let SelectedCols = [];
    let allColumns = [];
    let viewDetails = activatedView.viewDetails
      ? JSON.parse(activatedView?.viewDetails)
      : null;
    if (viewDetails !== null && viewDetails?.columns?.length > 0) {
      viewDetails.columns.forEach((vd) => {
        if (!excludedKeys?.includes(vd.header)) {
        allColumns.push({ label: vd.header, value: vd.header });
        if (!vd.hidden) {
            SelectedCols.push({ label: vd.header, value: vd.header });
          }
        }
      });

      const SelectedColsArray = SelectedCols.map((obj) => {
        const filteredObject = {};
        Object.keys(obj).forEach((key) => {
          if (!excludedKeys?.includes(key)) {
            filteredObject[key] = obj[key];
          }
        });
        return filteredObject;
      });
      // const filteredKeys =
      //   gridData?.length > 0 &&
      //   Object.keys(gridData[0]).filter((key) => !excludedKeys?.includes(key));
      // const resultArray =
      //   filteredKeys &&
      //   filteredKeys?.map((key) => ({ label: key, value: key }));
      // const finalTotalCols = resultArray.length >= allColumns.length ? resultArray : allColumns;
      setColumnOptions(_.uniqBy(allColumns, 'value'));
      setSelectedColumn(_.uniqBy(SelectedColsArray, 'value'));
      const fsc = _.filter(SelectedColsArray, item => !excludeFilterColumnKeys.includes(item.label));
      setFilterSelectedColumns(_.uniqBy(fsc, 'value'));
      if (columnButton !== "preview") {
        setInitSelectedColumns(_.uniqBy(SelectedColsArray, 'value'));
      }
    }
  };

  const UpdateFiltersFromView = (actView) => {
    let filter = actView.filters !== "[]" && actView.filters
      ? actView.filters
      : JSON.stringify(initialQuiryBuilder);
    SetQueryBuilder(JSON.parse(filter));
    setInitFilterQuery(JSON.parse(filter));
  };

  const getODataCondition = (val="") => {
    const matchedObj = _.find(gridColumns, { name: val });
    const numberType = _.includes(filterOperatorsNumberKeysOnly, val) ? "Number" : "";
    const dataType = numberType || matchedObj?.dataType;
    switch (dataType) {
      case 'String':
        return [...oDataConditions.common, ...oDataConditions.string];
      case 'Boolean':
        return oDataConditions.common;
      case 'Number':
      case 'Date':
      case 'DateTime':
        return [...oDataConditions.common, ...oDataConditions.number_date];
      default:
        return [...oDataConditions.common, ...oDataConditions.number_date, ...oDataConditions.string];
    }
  };

  const getKeyFromValue = (value) => {
    for (let key in HeaderKeyMappings) {
      if (HeaderKeyMappings[key] === value) {
        if (key.includes('__')) {
          const parts = key.split("__");

          key=parts[parts.length - 1]
          return key
        }
        return key;
      }
    }
    return value;
  };
  const handleClickButton = async (e, value, viewReset=false) => {
    setLoadingButton({ tab: selectedTab.value, button: value });
    setIsError(false);
    setErrorMessage("");
    try {
      toggleActionButtonsForAll(activeView, true, value);
      setNextActions([]);
      if(selectedTab.value === "filters") {
        setFilterButton(value)
      } else {
        // getFilterClicks(true);
        setColumnButton(value);
      }
      if (value === "preview") {
        //Handle preview option
        if (selectedTab.value === "filters") {
          const flterObj = {
            data: queryBuilder,
            action: "preview",
            type: "filters",
            subAction: "preview",
            viewDetails: previewView?.isColumn ? previewView?.viewDetails : activeView?.viewDetails,
          };
          await getUpdatedFilters(flterObj);
        } else {
          if (selectedColumn.length === 0) {
            return;
          }
          const updatedCols = {
            data: await transformTheLayout(),
            action: "preview",
            type: "columns",
            subAction: "preview",
          };
          await getUpdatedColumns(updatedCols);
        }
      } else if (value === "reset") {
        if (selectedTab.value === "filters") {
          const presentView = views.find((ele) => ele.name === activeView.name);
          let quryFlts = getQueryFilters(presentView);
          const flterObj = {
            data: JSON.parse(quryFlts),
            action: "reset",
            type: "filters",
            subAction: "reset",
            viewDetails: previewView?.isColumn ? previewView?.viewDetails : activeView?.viewDetails,
          };

          !viewReset && getUpdatedFilters(flterObj);
          SetQueryBuilder(JSON.parse(quryFlts));
        } else {
          const presentView = views.find((ele) => ele.name === activeView.name);
          let viewDetails = presentView.viewDetails
          ? JSON.parse(presentView?.viewDetails)
          : null;  
          const updatedCols = {
            data: viewDetails?.columns,
            action: "reset",
            type: "columns",
            subAction: "reset",
          };
          !viewReset && await getUpdatedColumns(updatedCols);
          await GetColumnsFromView(presentView);
        }
      } else if (value === "save") {
        if (activeView?.type.toLowerCase() === 'system') {
          setIsSystemView(true);
          setSystemViewMessage("You are now viewing the default view. Changes cannot be made in this view. Please switch to the user view to make any modifications.")
          return;
        }
        //Handle save option
        if (selectedTab.value === "filters") {
          const flterObj = {
            data: queryBuilder,
            action: "save",
            type: "filters",
            subAction: "save",
          };
          await getUpdatedFilters(flterObj);
        } else {
          if (selectedColumn.length === 0) {
            return;
          }
          const updatedCols = {
            data: await transformTheLayout(),
            action: "save",
            type: "columns",
            subAction: "save",
          };
          await getUpdatedColumns(updatedCols);
        }
      } else {
        //Handle cancel
        updateSidebar(false);
        if (selectedTab.value === "filters") {
          const presentView = views.find((ele) => ele.name === activeView.name);
          let quryFlts = getQueryFilters(presentView);
          const flterObj = {
            data: JSON.parse(quryFlts),
            action: "preview",
            type: "filters",
            subAction: "cancel",
            viewDetails: previewView?.isColumn ? previewView?.viewDetails : activeView?.viewDetails,
          };

          await getUpdatedFilters(flterObj);
          SetQueryBuilder(JSON.parse(quryFlts));
          setSelectedOperation("");
          setSelectedCondition("");
          setSelectedFilterColumn("");
        } else {
          const presentView = views.find((ele) => ele.name === activeView.name);
          let viewDetails = presentView.viewDetails
          ? JSON.parse(presentView?.viewDetails)
          : null;  
          const updatedCols = {
            data: viewDetails?.columns,
            action: "preview",
            type: "columns",
            subAction: "cancel",
          };
          await getUpdatedColumns(updatedCols);
          await GetColumnsFromView(presentView);
        }
      }
    } catch (err) {
      console.error("Error in handleClickButton:", err);
    } finally {
      setLoadingButton({ tab: null, button: null });
    }
  };

  const getQueryFilters = (activeView) => {
    return activeView.filters !== "" && activeView.filters !== null
      ? activeView.filters
      : JSON.stringify(initialQuiryBuilder);
  };

  const transformTheLayout = async () => {
    const primaryKeysHiddenColumns = primaryKeys?.map(columnValue => ({
        label: columnValue,
        value: columnValue,
    }));
    const hiddenColumns = columnOptions?.filter(column => 
        !selectedColumn?.some(sColumn => sColumn?.value === column?.value)
    );
    const totalHiddenColumns = [...(hiddenColumns || []), ...(primaryKeysHiddenColumns || [])];
    const combinedColumns = [...(selectedColumn || []), ...totalHiddenColumns];
    
    const selectedColumnMap = new Map(selectedColumn?.map(col => [col.value, col]));
    const viewDetails = activeView?.viewDetails ? JSON.parse(activeView.viewDetails) : { columns: [] };
    
    const existingFields = new Set(viewDetails.columns.map(item => item.field));
    const missingFields = selectedColumn?.filter(col => !existingFields.has(col.value)) || [];
    
    const missingColumns = missingFields.map(col => ({
        pinned: false,
        sortable: true,
        filterable: true,
        editable: false,
        sortingIgnoreCase: true,
        filteringIgnoreCase: true,
        headerClasses: "",
        headerGroupClasses: "",
        groupable: true,
        movable: false,
        hidden: false,
        dataType: "string",
        hasSummary: false,
        field: col.value,
        width: "auto",
        header: col.value,
        resizable: true,
        searchable: true,
        selectable: true,
        parent: null,
        columnGroup: false,
        disableHiding: false
    }));
    
    const updatedColumns = [...viewDetails.columns, ...missingColumns];
    
    const sortedColumns = updatedColumns.sort((a, b) => {
        const indexA = combinedColumns.findIndex(col => col.value === a.field);
        const indexB = combinedColumns.findIndex(col => col.value === b.field);
        return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
    });
    
    const finalColumns = sortedColumns.map(col => ({
        ...col,
        hidden: !selectedColumnMap.has(col.field)
    }));
    
    return finalColumns;
};

  const reorder = (list, startIndex, endIndex) => {
    toggleActionButtonsForAll(activeView, false);
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      selectedColumn,
      result.source.index,
      result.destination.index
    );
    setSelectedColumn(items);
    const fsc = _.filter(items, item => !excludeFilterColumnKeys.includes(item.label));
    setFilterSelectedColumns(fsc);
  };

  const handleSelectDropdownChange = (e, controlName) => {
    setErrorMessage("");
    setIsError(false);
    setDisableAdd(false);
    if (controlName === "column") {
      const foundItem = _.filter(queryBuilder, obj => obj?.isReadable === true && obj?.key === e?.label);
      if (e?.label && foundItem[0]?.key === e?.label) {
        setErrorMessage("Default filters are included. You cannot change the selected filters.");
        setIsError(true)
        setDisableAdd(true);
      }

      if (!e) {
        setSelectedOperation("");
        setEnteredValue("");
      }
      setSelectedFilterColumn(e);
      //Update name here
    } else if (controlName === "operator") {
      if (!e) {
        setEnteredValue("");
      }
      setSelectedOperation(e);
      //Update operatior here
    } else if (controlName === "condition") {
      if(!e) {
        setSelectedOperation("");
        setSelectedFilterColumn("");
        setEnteredValue("");
      }
      setSelectedCondition(e);
      //Update condition here
    } else {
      setEnteredValue(e.target.value);
      //Update value here
    }
    updateFilterCheck();
    updateSidebar(true);
  };

  function getRandomAlphaNumeric() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const randomIndex = Math.floor(Math.random() * characters.length);
    return characters[randomIndex];
  }

  function getRandomAlphaNumericString(length) {
    let result = "";
    for (let i = 0; i < length; i++) {
      result += getRandomAlphaNumeric();
    }
    return result;
  }

  const OnOperatorSelected = (expressionId, expressionValue, operationType, needToShowError) => {
      setErrorMessage("");
      setIsError(false);
    if(needToShowError){
      setIsError(true)
      setErrorMessage("Default filters are included. You cannot change the selected filters.")
      return
    }
    let nxtActs = [];
    const nxtActIndex = nextActions.findIndex(
      (nxtAct) => nxtAct === operationType
    );
    if (nxtActIndex !== -1) {
      let data = nextActions;
      data.splice(nxtActIndex, 1);
      setNextActions([...data]);
    } else {
      nxtActs.push(operationType);
      setNextActions(nxtActs);
      if (operationType === "operator") {
        const currOperation = getODataCondition(clickedExpression?.header || HeaderKeyMappings[clickedExpression?.key] || clickedExpression?.key).find(
          (x) => x.value === expressionValue
        );
        setCurrentOperation(currOperation);
      }

      if (operationType === "condition") {
        const currOperation = separateConditions.find(
          (x) => x.value === expressionValue
        );
        setCurrentOperation(currOperation);
      }
      setSelectedToggleExpression({
        expressionId: expressionId,
        operationType: operationType,
      });
    }
  };

  const OnExpressionSelected = (expression) => {
      setErrorMessage("");
      setIsError(false);
      if(expression?.isReadable){
        setIsError(true);
        setErrorMessage("Default filters are included. You cannot change the selected filters.");
        return
      }
    let selOps = selectedExpressions;
    queryBuilder.forEach((eachExp) => {
      if (eachExp.id === expression.id) {
        if (eachExp.isExpSelected === true) {
          //Unselect from selection list
          selOps = selOps.filter((selop) => selop.id !== expression.id);
        } else {
          // Add to selection list
          selOps.push(expression);
        }
        eachExp.isExpSelected = !eachExp.isExpSelected;
      }
    });
    setSelectedExpressions([...selOps]);
    SetQueryBuilder([...queryBuilder]);
    if (selOps.length > 1) {
      setNextActions(["group", "remove"]);
    } else if (selOps.length > 0){
      setNextActions(["remove"]);
    } else {
    const updatedStrings = nextActions.filter((str) => !(str === "group" || str === "remove"));
      setNextActions(updatedStrings);
    }
  };

  const onClickedExpression = (exp) => {
    setClickedExpression(exp);
  }

  const GetClassNameForexpression = (exp) => {
    let className = "filter-expression";
    if (exp.isExpSelected) {
      className = `${className} expression-active`;
    }
    return className;
  };

  const processSelection = (
    expressions,
    selectExpKey,
    startIdx,
    direction,
    selectedGrps
  ) => {
    // eslint-disable-next-line

    let inxx = startIdx;
    let matchKey = 1;

    while (inxx >= 0 && inxx < expressions.length) {
      if (expressions[inxx].type === "Group") {
        if (expressions[inxx].key === selectExpKey) {
          matchKey++;
        } else {
          matchKey--;
        }
      }

      if (matchKey === 0) {
        if (expressions[inxx].isExpSelected) {
          // eslint-disable-next-line
          selectedGrps = selectedGrps.filter(
            // eslint-disable-next-line no-loop-func
            (grp) => grp.id !== expressions[inxx].id
          );
          expressions[inxx].isExpSelected = false;
        } else {
          selectedGrps.push(expressions[inxx]);
          expressions[inxx].isExpSelected = true;
        }
        break;
      }

      if (direction === "right") {
        inxx++;
      } else if (direction === "left") {
        inxx--;
      }
    }
    return { exp: expressions, selGrps: selectedGrps };
  };

  const OnGroupSelected = (selectExp) => {
      // if(selectExp?.isReadable){
      //   setIsError(true)
      //   setErrorMessage("can not modify");
      //   return
      // }

    let expressions = queryBuilder;
    let selectedGrps = selectedGroups;

    let inx = expressions.findIndex((fi) => fi.id === selectExp.id);

    if (selectExp.key === "LeftKey") {
      if (expressions[inx].isExpSelected) {
        selectedGrps = selectedGrps.filter((grp) => grp.id !== selectExp.id);
        expressions[inx].isExpSelected = false;
      } else {
        selectedGrps.push(selectExp);
        expressions[inx].isExpSelected = true;
      }
      const data = processSelection(
        expressions,
        "LeftKey",
        inx + 1,
        "right",
        selectedGrps
      );
      expressions = data.exp;
      selectedGrps = data.selGrps;
    } else if (selectExp.key === "RightKey") {
      if (expressions[inx].isExpSelected) {
        selectedGrps = selectedGrps.filter((grp) => grp.id !== selectExp.id);
        expressions[inx].isExpSelected = false;
      } else {
        selectedGrps.push(selectExp);
        expressions[inx].isExpSelected = true;
      }
      const data = processSelection(
        expressions,
        "RightKey",
        inx - 1,
        "left",
        selectedGrps
      );
      expressions = data.exp;
      selectedGrps = data.selGrps;
    }

    if (selectedGrps.length > 1) {
      setNextActions(["ungroup"]);
    } else {
      setNextActions([]);
    }
    setSelectedGroups([...selectedGrps]);
    SetQueryBuilder([...expressions]);
  };

  const GetClassNameForGroup = (selectExp) => {
    return selectExp.isExpSelected ? "active-group" : "";
  };

  const addFilterCondition = () => {
    let expressions = queryBuilder;
    if (queryBuilder?.length > 2 && isShowCondition && !selectedCondition?.value) {
      setErrorMessage(handleErrorMessage("AIMS360_Runway_Standard_Grid_1805"));
      setIsError(true);
      return;
    } else if (!selectedFilterColumn?.value) {
      setErrorMessage(handleErrorMessage("AIMS360_Runway_Standard_Grid_1806"));
      setIsError(true);
      return;
    } else if (!selectedOperation?.value) {
      setErrorMessage(handleErrorMessage("AIMS360_Runway_Standard_Grid_1807"));
      setIsError(true);
      return;
    } else if (!enteredValue) {
      setErrorMessage(handleErrorMessage("AIMS360_Runway_Standard_Grid_1808"));
      setIsError(true);
      return;
    }
    toggleActionButtonsForAll(activeView, false);
    if (
      selectedCondition?.value !== null &&
      selectedCondition?.value !== undefined &&
      selectedCondition?.value !== ""
    ) {
      let expressionCondition = {
        id: getRandomAlphaNumericString(10),
        key: "",
        value: "",
        operator: "",
        condition: selectedCondition?.value,
        isExpSelected: false,
        type: "Condition",
      };
      expressions.splice(queryBuilder.length - 1, 0, expressionCondition);
    }

    let expression = {
      id: getRandomAlphaNumericString(10),
      key: getKeyFromValue(selectedFilterColumn?.value),
      header: selectedFilterColumn?.value,
      value: enteredValue,
      operator: selectedOperation?.value,
      condition: "",
      isExpSelected: false,
      type: "Expression",
    };

    expressions.splice(queryBuilder.length - 1, 0, expression);
    setShowCondition(true);
    SetQueryBuilder({});
    let temp = queryBuilder;
    SetQueryBuilder(temp);
    cleanUpStates();
  };

  const onNextOperationsSelected = (operationval, operationType) => {
    toggleActionButtonsForAll(activeView, false);
    queryBuilder.forEach((eachItem) => {
      if (eachItem.id === selectedToggleExpression.expressionId) {
        eachItem[operationType] = operationval.value;
      }
    });
    setCurrentOperation(operationval);
    const updatedStrings = nextActions.filter((str) => (str !== operationType));
    setNextActions(updatedStrings);
    updateFilterCheck();
    updateSidebar(true);
  };

  const cleanUpStates = () => {
    setSelectedFilterColumn("");
    setEnteredValue("");
    setSelectedCondition("");
    setSelectedOperation("");
  };

  const UnGroupSelectedItems = () => {
    let queries = [...queryBuilder];
    toggleActionButtonsForAll(activeView, false);
  
    // Sort selectedGroups by their index in queries (descending order)
    const sortedSelectedGroups = selectedGroups
      .map(group => ({
        ...group,
        index: queries.findIndex(item => item.id === group.id)
      }))
      .sort((a, b) => b.index - a.index);
  
    sortedSelectedGroups.forEach(selectedGroup => {
      const index = selectedGroup.index;
      if (index !== -1) {
        // Remove the brace
        queries.splice(index, 1);
  
        // Check surrounding elements
        const prevElement = index > 0 ? queries[index - 1] : null;
        const nextElement = index < queries.length ? queries[index] : null;
  
        // Only remove condition if it's not between a brace and an expression
        if (prevElement?.type === "Condition" && 
            !(nextElement?.type === "Expression" || 
              (nextElement?.type === "Group" && nextElement.key.includes("Left")))) {
          queries.splice(index - 1, 1);
        } else if (nextElement?.type === "Condition" && 
                   !(prevElement?.type === "Expression" || 
                     (prevElement?.type === "Group" && prevElement.key.includes("Right")))) {
          queries.splice(index, 1);
        }
      }
    });
  
    SetQueryBuilder(queries);
    setSelectedGroups([]);
    const updatedStrings = nextActions.filter((str) => str !== "ungroup");
    setNextActions(updatedStrings);
    updateFilterCheck();
    updateSidebar(true);
  };

  const GroupSelectedItems = () => {
    let indexes = [];
    toggleActionButtonsForAll(activeView, false);
    selectedExpressions.forEach((seleExp) => {
      const matchIndex = queryBuilder.findIndex((qb) => qb.id === seleExp.id);
      if (matchIndex >= 0) {
        indexes.push(matchIndex);
      }
    });
    const orderedNumbers = indexes.slice().sort((a, b) => a - b);
    let queries = queryBuilder;
    queries.splice(orderedNumbers[0], 0, getGroupLeftData());
    queries.splice(
      orderedNumbers[orderedNumbers.length - 1] + 2,
      0,
      getGroupRightData()
    );
    SetQueryBuilder([...queries]);
    restSelections();
    updateFilterCheck();
    updateSidebar(true);
  };

  const checkTheCondition = (ind, firstExpressionMatched) => {
    let conditionIndexes = [];
    const conditionsInIndexes = [ind - 1, ind + 1];
    conditionsInIndexes.forEach(index => {
      if (queryBuilder[index].type === "Condition") {
        conditionIndexes.push(index);
      }});
    if (conditionIndexes.length === 2) {
      firstExpressionMatched ? conditionIndexes.shift() : conditionIndexes.pop();
    }
    return conditionIndexes;
  }

  const RemoveSelectedItems = () => {
    let indexes = [];
    let withConditionIndex = [];
    toggleActionButtonsForAll(activeView, false);
    const firstSelectedExpression = _.find(queryBuilder, expression => expression.type === 'Expression');
    const firstExpressionMatched = selectedExpressions.find((qb) => qb.id === firstSelectedExpression.id);

    selectedExpressions.forEach((seleExp) => {
      const matchIndex = queryBuilder.findIndex((qb) => qb.id === seleExp.id);
      if (matchIndex >= 0) {
        indexes.push(matchIndex);
      }
    });
    indexes.forEach(i => {
      const consitionIndex = checkTheCondition(i, firstExpressionMatched);
      withConditionIndex.push(i, ...consitionIndex)
    })
    const orderedNumbers = withConditionIndex.slice().sort((a, b) => a - b);
    const updatedArray = queryBuilder.filter((_, index) => !orderedNumbers.includes(index));
    SetQueryBuilder([...updatedArray]);
    setSelectedExpressions([]);
    const updatedStrings = nextActions.filter((str) => !(str === "group" || str === "remove"));
    setNextActions(updatedStrings);
    updateFilterCheck();
    updateSidebar(true);
  }

  const restSelections = () => {
    setSelectedExpressions([]);
    let queries = queryBuilder;
    queries.forEach((que) => {
      que.isExpSelected = false;
    });
    SetQueryBuilder([...queries]);
    const updatedStrings = nextActions.filter((str) => !(str === "group" || str === "remove"));
    setNextActions(updatedStrings);
  };

  const getGroupLeftData = () => {
    return {
      id: getRandomAlphaNumericString(10),
      key: "LeftKey",
      value: "(",
      operator: "",
      condition: "",
      isExpSelected: false,
      type: "Group",
    };
  };

  const getGroupRightData = () => {
    return {
      id: getRandomAlphaNumericString(10),
      key: "RightKey",
      value: ")",
      operator: "",
      condition: "",
      isExpSelected: false,
      type: "Group",
    };
  };

  const OnTabSelected = (tab) => {
    setSelectedTab(tab);
    setIsError(false);
    setErrorMessage("");
    if (tab.value === "columns") {
      toggleActionButtonsForAll(activeView, hasChanged(initSlectedColumns, selectedColumn), columnButton);
    } else {
      toggleActionButtonsForAll(activeView, hasChanged(initFilterQuery, queryBuilder), filterButton);
    }
  };

  const handleScroll = (event) => {
    event.stopPropagation();
  };  

  const memoizedGetQueryString = useCallback(() => {
    return GetQueryString(
      queryBuilder,
      OnOperatorSelected,
      GetClassNameForGroup,
      OnGroupSelected,
      GetClassNameForexpression,
      OnExpressionSelected,
      HeaderKeyMappings,
      filterQueryBuilder,
      shouldDisplayExpression,
      mapExpressionValue,
      onClickedExpression,
    );
  }, [queryBuilder, OnOperatorSelected, GetClassNameForGroup, OnGroupSelected, 
      GetClassNameForexpression, OnExpressionSelected, HeaderKeyMappings, DynamicFilters]);

  return (
    <CTabs activeTab="columns">
      <CNav variant="tabs" className="height-26">
        {tabLinks.map((ele, index) => (
          <CNavItem
            key={ele.value}
            onClick={() => OnTabSelected(ele)}
            className="h-100 d-flex"
          >
            <CNavLink data-tab={ele.value} className="nav-link-inActive padding-top-2">
              {ele.label}
            </CNavLink>
            <span className="d-flex align-items-center text-secondary">|</span>
          </CNavItem>
        ))}
      </CNav>
      <CTabContent>
        <CTabPane data-tab="filters" className="searchItems">
          {(queryBuilder.length > 2 && isShowCondition) && (
            <>
              <AReactSelect
                className="w-100 mr-2 mt-2"
                options={separateConditions}
                onChange={(e) => handleSelectDropdownChange(e, "condition")}
                placeholder={<div>Select Condition</div>}
                isClearable={true}
                isSearchable={true}
                value={selectedCondition}
              />
            </>
          )}

          <AReactSelect
            className="w-100 mr-2 mt-2"
            options={filterSelectedColumns}
            onChange={(e) => handleSelectDropdownChange(e, "column")}
            placeholder={<div>Select Column</div>}
            isClearable={true}
            isSearchable={true}
            value={selectedFilterColumn}
          />

          <AReactSelect
            className="w-100 mr-2 mt-2"
            options={getODataCondition(selectedFilterColumn?.value)}
            // isDisabled={sizeList.length === 0}
            onChange={(e) => handleSelectDropdownChange(e, "operator")}
            placeholder={<div>Select Operation</div>}
            isClearable={true}
            isSearchable={true}
            value={selectedOperation}
          />

          <CInput
            type="text"
            name={"value"}
            placeholder={"Enter Value"}
            autoComplete="off"
            value={enteredValue}
            className="pl-1 mt-2"
            onChange={(e) => {
              handleSelectDropdownChange(e, "value");
            }}
          />

          <CCol
            md="12"
            xs="12"
            key="AddCondition"
            style={{ paddingRight: "0px", textAlign: "end", marginTop: "5px" }}
          >
            <CButton
              onClick={(e) => addFilterCondition()}
              color="primary"
              disabled={disableAdd}
              // className="w-15"
            >
              <div>Add</div>
            </CButton>
          </CCol>
          <div className="text-danger">
            {isError && errorMessage ? errorMessage : ""}
          </div>
          <CCol
            md="12"
            xs="12"
            className="mt-3 next-actions-pannel pl-0"
            style={{ paddingRight: "0px", height: "40px" }}
          >
            {nextActions.length > 0 && nextActions.includes("remove") && (
              <CButton
                onClick={(e) => RemoveSelectedItems()}
                color="primary"
                className={selectedExpressions.length > 1 && "mr-3"}
              >
                <div>Remove</div>
              </CButton>
            )}
            {nextActions.length > 0 && nextActions.includes("operator") && (
              <>
                <AReactSelect
                  className="w-50 mt-1 mb-2 text-align-start"
                  options={getODataCondition(clickedExpression?.header || HeaderKeyMappings[clickedExpression?.key] || clickedExpression?.key)}
                  // isDisabled={sizeList.length === 0}
                  onChange={(e) => onNextOperationsSelected(e, "operator")}
                  placeholder={<div>Select Operation</div>}
                  isClearable={false}
                  isSearchable={true}
                  value={currentOperation}
                />
              </>
            )}

            {nextActions.length > 0 && nextActions.includes("condition") && (
              <>
                <AReactSelect
                  className="w-30 mt-2 mb-2 text-align-start"
                  options={separateConditions}
                  // isDisabled={sizeList.length === 0}
                  onChange={(e) => onNextOperationsSelected(e, "condition")}
                  placeholder={<div>Select Condition</div>}
                  isClearable={false}
                  isSearchable={true}
                  value={currentOperation}
                />
              </>
            )}

            {nextActions.length > 0 && nextActions.includes("group") && (
              <>
                <CButton
                  onClick={(e) => GroupSelectedItems()}
                  color="primary"
                  className=""
                >
                  <div>Group</div>
                </CButton>
              </>
            )}

            {nextActions.length > 0 && nextActions.includes("ungroup") && (
              <>
                <CButton
                  onClick={(e) => UnGroupSelectedItems()}
                  color="primary"
                  className=""
                >
                  <div>Ungroup</div>
                </CButton>
              </>
            )}
          </CCol>
          <CCard
            onScroll={handleScroll}
            className="shadow overflow-auto"
            style={{ border: "1px solid lightslategray", height: "150px" }}
          >
            <CLabel name="query" className="mt-2">
              {memoizedGetQueryString()}
            </CLabel>
          </CCard>

          {/* <CTextarea
            rows={5}
            name="query"
            value={`${query}`}
          /> */}
          <CRow>
            {filterButtonData.map((ele) => (
              <CCol md="6" xs="6" className="mt-3" key={ele.value}>
              {ele.isUserHasOnlyReadAccess ?
                <CTooltip
                content={readPermissionMessage}
                placement="top"
              >
                <CLink>
                  <AButton
                    text={ele.label}
                    color="primary"
                    className={ele.btnClass}
                    disabled
                  />
                </CLink>
              </CTooltip> :
              <AButton
              onClick={(e) => handleClickButton(e, ele.value)}
              className={ele.btnClass}
              text={ele.label}
              isLoading={loadingButton.tab === selectedTab.value && loadingButton.button === ele.value}
              />
            }
              </CCol>
            ))}
          </CRow>
        </CTabPane>
        <CTabPane data-tab="columns">
          <CSidebarNavItem className="searchItems">
            <div className="row">
              <div className="col-md-12">
                <AMultiSelect
                  name="columns"
                  options={columnOptions}
                  value={selectedColumn}
                  onChange={(e) => {
                    toggleActionButtonsForAll(activeView, e.length <= 0);
                    setSelectedColumn(_.uniqBy(e, 'value'));
                    const fsc = _.filter(e, item => !excludeFilterColumnKeys.includes(item.label));
                    setFilterSelectedColumns(_.uniqBy(fsc, 'value'));              
                    updateFilterCheck();
                    updateSidebar(true);
                  }}
                  labelledBy={"Select"}
                  isCreatable={false}
                  className="grid-dropdown"
                />
              </div>
            </div>
          </CSidebarNavItem>
          <CSidebarNavItem className="mt-3 height-56">
            <b className="searchItems">
              SELECTED COLUMNS ({selectedColumn?.length})
            </b>
            <CListGroup className="searchItems height-56 my-0 py-0">
              {selectedColumn?.length > 0 && (
                <CListGroupItem className="py-0 my-0 height-100 overflow-x">
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="selctedColumns">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="pb-2"
                        >
                          {selectedColumn?.map((ele, index) => (
                            <Draggable
                              key={ele.label + index}
                              draggableId={index.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <CCard
                                    className={`${
                                      darkMode
                                        ? "draggable-item-dark-bg"
                                        : "draggable-item-bg"
                                    } text-dark py-0 mb-0 mt-2`}
                                  >
                                    <div className="d-flex justify-content-between align-items-center ">
                                      <div className="d-flex align-items-center">
                                        <FontAwesomeIcon
                                          icon={faAlignJustify}
                                          className="ml-3 mr-4"
                                        />
                                        <div>{ele.label}</div>
                                      </div>
                                      {/* <FontAwesomeIcon icon={faX} className='mr-3' onClick={() => {handleItemClick(ele)}}/> */}
                                    </div>
                                  </CCard>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </CListGroupItem>
              )}
            </CListGroup>
          </CSidebarNavItem>
          <CSidebarNavItem className="searchItems">
            <CRow>
              <CCol md="12" xs="12" className="text-danger">{selectedColumn.length <= 0 && handleErrorMessage("AIMS360_Runway_Standard_Grid_1809")}</CCol>
                {filterButtonData.map((ele) => (
                  <CCol md="6" xs="6" className="mt-3" key={ele.value}>
                  {ele.isUserHasOnlyReadAccess ?
                    <CTooltip
                      content={readPermissionMessage}
                      placement="top"
                    >
                      <CLink>
                        <AButton
                          text={ele.label}
                          color="primary"
                          className={ele.btnClass}
                          disabled
                        />
                      </CLink>
                    </CTooltip> :
                    <AButton
                      onClick={(e) => handleClickButton(e, ele.value)}
                      className={ele.btnClass}
                      text={ele.label}
                      isLoading={loadingButton.tab === selectedTab.value && loadingButton.button === ele.value}
                    />
                  }
                  </CCol>
                ))}
            </CRow>
          </CSidebarNavItem>
        </CTabPane>
      </CTabContent>
    </CTabs>
  );
}

export default FilteredColumns;
