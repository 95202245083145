const reactSelectStyles = (darkMode,selected) => ({
  option: (provided) => ({
    ...provided,
    color: darkMode ? "rgba(255, 255, 255, 0.87)" : "",
  }),

  singleValue: (provided) => ({
    ...provided,
    color: darkMode ? "rgba(255, 255, 255, 0.87)" : "#5c6873",
  }),
  input: (provided) => ({
    ...provided,
    color: darkMode ? "rgba(255, 255, 255, 0.87)" : "#5c6873",
  }),
  control: (base) => ({
    ...base,
    borderColor: darkMode ? "rgba(255, 255, 255, 0.09)" : "#e4e7ea",
    background: darkMode ? "#2f303a" : "",
    // maxWidth : details.id?'100px':'',
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: "",
    },
  }),
});

// for react selcet theme
const reactSelectTheme = (theme, darkMode,name="") => {
  let primaryColor;
  if (darkMode && name === "timezone") {
    primaryColor = "#ffc107";
  } else if (name === "timezone") {
    primaryColor = "#ffc107";
  } else {
    primaryColor = theme.colors.primary;
  }
  return{

  ...theme,
  colors: {
    ...theme.colors,
    primary: primaryColor,
    primary25: darkMode ? "black" : theme.colors.primary25,
    dangerLight: darkMode ? "black" : theme.colors.dangerLight,
    neutral0: darkMode ? "#2a2b36" : theme.colors.neutral0,
  },
  spacing: {
    ...theme.spacing,
    controlHeight: 35,
    baseUnit: 1,
  },
}
};
const NumberWithCommas = (value) => {
  return value ? value.toLocaleString("en-US") : 0;
};

export { reactSelectStyles, reactSelectTheme, NumberWithCommas };