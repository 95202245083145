import { v4 as uuidv4 } from 'uuid';
import { getTokenFromLocalStorage } from '../../../../Services/LocalStorageService';
import createInterceptorInstance from '../../../../Services/Interceptor';
import axios from 'axios';

const GetRequest = async (url, logger, setLogLevel = null, cancelToken = false) => {
  const guid = uuidv4();
  try {
      const URL = createInterceptorInstance(url);
      const source = cancelToken ? axios.CancelToken.source() : null;

      const response = await URL.get('', {
          // withCredentials: true,
          headers: {
              "Authorization": `Bearer ${getTokenFromLocalStorage()}`,
          },
          ...(cancelToken && { cancelToken: source.token })
      });

      logger('debug', guid, response, "AIMS360RunwayWMS", url, "GET"); // log request and response data

      if (cancelToken) {
          return {
              promise: response,
              cancel: source ? () => source.cancel('Request canceled') : null
          };
      } else {
          return response;
      }
  } catch (error) {
      logger('error', guid, error, "AIMS360RunwayWMS", url, "GET"); // log error
      throw error;
  }
};

export{GetRequest}