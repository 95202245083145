import axios from "axios";
import { getTokenFromLocalStorage } from '../Services/LocalStorageService';

const createInterceptorInstance = (baseURL) => {
  const api = axios.create({
    baseURL,
  });
  
  api.interceptors.request.use(
    (config) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      //config.headers['Origin'] = window.location.pathname
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(

    (response) => {
      if (response?.status === 401) {
        if(response?.data?.error?.code === "ERR-ModulePermission-1001-PO"){
          localStorage.setItem("PermissionCode",true);
        }
        localStorage.setItem("Unauthorised",true);
      }
      else if (response?.data?.status === 401 || response?.data?.statusCode === 401) {
        if(response?.data?.data?.error?.code === "ERR-ModulePermission-1001-PO"){
          localStorage.setItem("PermissionCode",true);
        }
        localStorage.setItem("Unauthorised",true);
      }
      else
      {
        return response;
      } 
    },
    (error) => {
      if (error?.response?.status === 401) {
        if(error?.response?.data?.aims360Error?.code === "ERR-ModulePermission-1001-PO" || error?.response?.data?.Aims360Error?.Code === "ERR-ModulePermission-1001-PO"){
          localStorage.setItem("PermissionCode",true);
        }
        localStorage.setItem("Unauthorised",true);
      }
      return Promise.reject(error);
    }
  );
    return api;
};

export default createInterceptorInstance;