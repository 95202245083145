import React from 'react';
import AButton from './AButton';
import { CLink, CTooltip } from '@coreui/react';
import { readPermissionMessage } from '../Services/UserAccessPermission';

const SaveCancelButton = ({ onSave, onCancel,spinner,disable,cancelDisable,isReadOnlyPermission}) => {
    return (
        <div className='d-flex float-right'>
            <AButton className="btn btn-secondary mr-2 px-4" onClick={onCancel} disabled={cancelDisable}  text="Cancel"/>
            {
                    isReadOnlyPermission ? (
                        <CTooltip content={readPermissionMessage} placement="top">
                        <CLink>
                            <AButton className="btn btn-success mr-2 px-4"   style={{ cursor: "not-allowed" }} onClick={onSave} isLoading={spinner} text="Save" disabled={true} />
                        </CLink>
                        </CTooltip>
                    ) : (
                        <AButton className="btn btn-success mr-2 px-4" onClick={onSave} isLoading={spinner} text="Save" disabled={disable} />
                    )
            }
        </div>
    );
};

export default SaveCancelButton;