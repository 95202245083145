/* eslint-disable react-hooks/exhaustive-deps */
import { CAlert, CButtonClose, CCol, CRow } from "@coreui/react";
import { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import axios from "axios";
import {
  getTokenFromLocalStorage,
  getZoneFromLocalStorage,
} from "../Services/LocalStorageService";
import AButton from "../Components/AButton";
import "../Components/Component.scss";
import ReactDOMServer from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import { getLabel } from "../Resources/content";
import { getURLByZone } from "../Services/endpoints.service";

export default function handleErrorMessage(messageCode, ...props) {
  const val = localStorage.getItem("ErrorMessages") ? JSON.parse(localStorage.getItem("ErrorMessages")) : [];
  const userSettingsData = JSON.parse(localStorage.getItem('userSettingsData'));
  const errorCode=userSettingsData?.error_code === "enabled";
  if (val) {
    for (const each of val) {
      if (each.messageCode === messageCode) {
        let msg = each.message;
        msg = msg.replace(/%(\w+)%/g, (match, p1) => {
          return props.length > 0 && typeof props[0][p1] !== "undefined"
            ? props[0][p1]
            : "";
        });
        const code = messageCode.slice(-4);
        // Ensure that this console statement remains intact for error monitoring purposes.
        console.log(`${msg} Err-Code: ${code}`)
        let errormsgCode=errorCode ?", Message Code: "+messageCode:"";
        let errmsg=msg;
        let displayError=errmsg+errormsgCode;
        if (displayError.includes(", M")) {
          let parts = displayError?.split(", M");
          let part1 = parts[0]; 
          let part2 = "M"+parts[1]; 
          return(<>
            <div>{part1}</div>
            <div>{part2}</div>
            </>)
        } else {
          return displayError;
        }
      }
    }
  }
}

export  function handleErrorCode(errormsg,errorCode){
  let errormsgCode=errorCode ?", Message Code: "+errormsg?.messagecode:"";
        let errmsg=errormsg?.message;
        let displayError=errmsg+errormsgCode;

        return displayError
}

export function HandleMessageFromResponse({ data,flag }) {
  const [moreDetails, setMoreDetails] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const screenshotRef = useRef(null);
  const [success, setSuccess] = useState(false);
  let userSettingsData = JSON.parse(localStorage.getItem('userSettingsData'));
 let errorCode=userSettingsData?.error_code === "enabled"

  useEffect(() => {
    if (
      data !== null &&
      typeof data === "object" &&
      Object.keys(data)?.length !== 0
    ) {
      setAlertVisible(true);
      setMoreDetails(false);
      setAlertMessage("");
      setLoading(false);
    }
  }, [data]);
  if (!data || (typeof data === "object" && Object.keys(data)?.length === 0)) {
    return null;
  }
  const {
    response: {
      data: {
        statusCode,
        runwayRequestId,
        runwayError,
        stackTrace,
        aimsRequestId,
        aims360Error,
      } = {},
    } = {},
  } = normalizeKeys(data);
  const handleCaptureClick = async () => {
    setLoading(true);
    const htmlString = ReactDOMServer.renderToString(getErrorBody());
    let combinedImageSrc = null;
    const generateCombinedImage = async () => {
      try {
        if (screenshotRef.current) {
          const canvas = await html2canvas(document.documentElement);
          const imageSrc = canvas.toDataURL("image/png");
          
          // Create a new image element for the screenshot
          const screenshotImage = new Image();
          screenshotImage.src = imageSrc;
          
          await new Promise((resolve) => {
            screenshotImage.onload = () => {
              // Create a new canvas for the combined image
              const combinedCanvas = document.createElement('canvas');
              combinedCanvas.width = screenshotImage.width;
              combinedCanvas.height = screenshotImage.height + 30; // Extra space for the URL
          
              const ctx = combinedCanvas.getContext('2d');
          
              // Draw the URL at the top
              const url = window.location.href;
              ctx.fillStyle = 'white';
              ctx.fillRect(0, 0, combinedCanvas.width, 30); // Background for URL
              ctx.fillStyle = 'black';
              ctx.fillText(url, 10, 20); // Drawing URL text
          
              // Draw the screenshot below the URL
              ctx.drawImage(screenshotImage, 0, 30);
          
              // Convert combined canvas to image
              combinedImageSrc = combinedCanvas.toDataURL("image/png");
              resolve();
            };
          });
        }
      } catch (error) {
        console.error("Error generating combined image:", error);
      }
    };
    
    // Call the async function
    generateCombinedImage().then(() => {
      const zone = getZoneFromLocalStorage();
      let url = getURLByZone("REACT_APP_POSTZENDESK", zone);      
      let link = "";
      const urlHistory = localStorage.getItem("URLHistory") ? JSON.parse(localStorage.getItem("URLHistory")) : {};
      urlHistory.forEach((ele, index) => {
        link = `${link} ${ele.path} ${urlHistory?.length - 1 !== index ? " > " : ""}`;
      })
      const payload = {
        subject: `${link} - ${
          runwayError?.message || aims360Error?.message
        }`,
        commentBody: htmlString,
        upload: combinedImageSrc,
      };
    
    axios
      .post(url, JSON.stringify(payload), {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      })
      .then((res) => {
        setSuccess(true);
        setAlertMessage(`${res?.data?.message} Ticket ID: ${res?.data?.ticket}`);
        setLoading(false);
      })
      .catch((err) => {
        setSuccess(false);
        setAlertMessage("Failed attempt to create a ticket.");
        setLoading(false);
      });
  });
  };
  const getErrorBody = () => {
    return (
      <div style={{ overflowY: 'scroll', height: '200px' }}>
        <small>
          <strong>Status Code:</strong> {statusCode}
          <small>
            {moreDetails && (
              <CButtonClose
                onClick={() => {
                  setAlertVisible(false);
                }}
                color="dark"
              />
            )}
          </small>
        </small>
        <br />
        {runwayRequestId && (
          <>
            <small>
              <strong>Runway Request Id:</strong> {runwayRequestId}
            </small>
            <br />
          </>
        )}
        {runwayError?.code && errorCode &&(
          <>
            <small>
              <strong>Runway Error Code:</strong> {runwayError?.code}
            </small>
            <br />
          </>
        )}
        {aimsRequestId && (
          <>
            <small>
              <strong>Aims360 Request Id:</strong> {aimsRequestId}
            </small>
            <br />
          </>
        )}
        {aims360Error?.code && errorCode &&(
          <>
            <small>
              <strong>Aims360 Error Code:</strong> {aims360Error?.code}
            </small>
            <br />
          </>
        )}
        <small>
        <strong>Message:</strong>{" "}
        {runwayError?.message || aims360Error?.message}
        </small>
        <br />
        {stackTrace && (
          <>
            <small style={{ wordWrap: "break-word" }}>
              <strong>Stack Trace :</strong> {stackTrace}
            </small>
            <br />
          </>
        )}
       
      </div>
    );
  };
  const getResponseErrorCode=()=>{
    let errormsg=runwayError?.message? runwayError?.message :  aims360Error?.message;
    let msgCode=runwayError?.code ?runwayError?.code : aims360Error?.code;
    return(
    <div>
      <div className="mb-1">{errormsg}</div>
      <div className="mb-1">{RequestId}</div>
      {errorCode? <div>Message Code: {msgCode}</div>:""}
     
    </div>)
  }
  const RequestId = (runwayRequestId || aimsRequestId) ? `${getLabel("RequestID")} : ${ runwayRequestId || aimsRequestId}` : "";
  return (
    alertVisible && (
      statusCode === 500 || statusCode === 417 ||statusCode === 501||statusCode === 502||statusCode === 503?
      <CAlert color="danger">
        <CRow>
          <CCol className="pr-0" sm="12" md="12">
            {!moreDetails ? (
              <div>
                <small>
                  <strong className="text-danger">
                    {runwayError?.message || aims360Error?.message}
                  </strong>
                </small>
                <div className="alert-button p-3">
                  <AButton
                    color="primary"
                    onClick={() => {
                      setMoreDetails(true);
                    }}
                    className="float-right"
                    size="sm"
                    text="View More"
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="text-danger">{getErrorBody()}</div>
                <div className="text-center">
                  <small>
                    <strong className={`${success ? "text-success" : "text-danger"}`}>{alertMessage}</strong>
                  </small>
                  <div className="alert-button p-3" ref={screenshotRef}>
                    <AButton
                      className="float-right"
                      size="sm"
                      color="primary"
                      onClick={handleCaptureClick}
                      text="Contact Support"
                      isLoading={loading}
                      disabled={success}
                    />
                  </div>
                </div>
              </>
            )}
          </CCol>
        </CRow>
      </CAlert> :
      <CAlert color="danger">
        <CRow>
        <CCol xs="12" sm="12" md="12" className="p-0">
        <CCol className="pl-0 closebtn">
          <CButtonClose
            onClick={() => {
              setAlertVisible(false);
            }}
            color="dark"
            className={`${flag?'d-none':''}`}
          />
        </CCol>
          <small>
            <strong className="text-danger">
              {statusCode === undefined ? handleErrorMessage("AIMS360_Runway_API_ERR_5002") :getResponseErrorCode() }
            </strong>
          </small>
        </CCol>
        
        </CRow>
      </CAlert>
    )
  );
}

export function HandleMessageFromUI({ message, classNames="", setMessage=null }) {
  const [alertVisible, setAlertVisible] = useState(true);
  const uiRequestId = useSelector(state => state.uiRequestId);
  const dispatch = useDispatch();
  const userSettingsData = JSON.parse(localStorage.getItem('userSettingsData'));
  const errorCode=userSettingsData?.error_code === "enabled";
  const value=extractChildrenValues(message)
  let msgcode="";
  let errmsg="";
  let msg
  if(typeof value === "string" && value.includes("Message Code")){
     msg = value?.split('Message Code')
     msgcode="Message Code"+msg?.[1];
     errmsg=msg[0];
  }
  else{
    errmsg=message;

  }
  let content = "";

  useEffect(() => {
    setAlertVisible(true);
    dispatch({type: "set", uiRequestId: ""});
  }, [message])


  if (uiRequestId) {
      content = ` ${getLabel("RequestID")} : ${uiRequestId}`;
  }

  return (
    alertVisible &&
    <CAlert color={`danger ${classNames}`}>
      <CRow>
        <CCol xs="10" sm="11" md="11" className="p-0">
          <small>
            {message ?<>
            {errmsg?
            <div>
              <strong className="text-danger">{`${errmsg?errmsg:""}`}</strong>
            </div>
            :""}
             <strong className="text-danger">{content?content:""}</strong>
            {msgcode && errorCode ?
            <div>
              <strong className="text-danger text-break">{`${msgcode?msgcode:""}`}</strong>
            </div>
            :""}
              
            </>:""}
          </small>
        </CCol>
        <CCol xs="2" sm="1" md="1" className="p-0 pr-1 adj_top">
          <CButtonClose
            onClick={() => {
              setAlertVisible(false);
              setMessage && setMessage("");
              dispatch({type: "set", uiRequestId: ""});
            }}
            color="dark"
            className="float-right"
          />
        </CCol>
      </CRow>
    </CAlert>
  );
}

function extractChildrenValues(obj) {
  if (obj?.props && obj?.props?.children) {
      if (typeof obj.props.children === 'string') {
          return obj.props.children;
      } else if (Array.isArray(obj.props.children)) {
          let childrenValues = obj.props.children.map(child => {
              if (typeof child === 'string') {
                  return child;
              } else if (typeof child === 'object' && child.props && child.props.children) {
                  return extractChildrenValues(child);
              }
              return '';
          }).join(' ');

          return childrenValues.trim();
      }
  }

  return obj;
}

function normalizeKeys(obj, seen = new Set()) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (seen.has(obj)) {
    return obj;
  }

  seen.add(obj);

  return Object.keys(obj).reduce((acc, key) => {
    const normalizedKey = key.charAt(0).toLowerCase() + key.slice(1);
    const value = obj[key];

    if (typeof value === 'object' && value !== null) {
      acc[normalizedKey] = normalizeKeys(value, seen); // Recursively normalize nested objects
    } else {
      acc[normalizedKey] = value;
    }

    return acc;
  }, {});
}
