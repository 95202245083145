export const AquaSelectValues = [
  {
    "Category": "Codes",
    "Source": "Zip Codes",
    "ViewName": "Default View"
  },
  {
    "Category": "Codes",
    "Source": "Body Codes",
    "ViewName": "All Body Codes"
  },
  {
    "Category": "Codes",
    "Source": "Seasons",
    "ViewName": "All Seasons"
  },
  {
    "Category": "Codes",
    "Source": "Divisions",
    "ViewName": "All Divisions"
  },
  {
    "Category": "Codes",
    "Source": "Debit Adjustments",
    "ViewName": "All Debit Adjustments"
  },
  {
    "Category": "Codes",
    "Source": "Credit Adjustments",
    "ViewName": "All Credit Adjustments"
  },
  {
    "Category": "Codes",
    "Source": "Classify Customers",
    "ViewName": "All Classify Customers"
  },
  {
    "Category": "Codes",
    "Source": "Classify Vendors",
    "ViewName": "All Classify Vendors"
  },
  {
    "Category": "Codes",
    "Source": "Return Authorizations",
    "ViewName": "All Return Authorizations"
  },
  {
    "Category": "Codes",
    "Source": "Special Instructions",
    "ViewName": "All Special Instructions"
  },
  {
    "Category": "Codes",
    "Source": "Style Groups",
    "ViewName": "All Style Groups"
  },
  {
    "Category": "Codes",
    "Source": "MFG Operations",
    "ViewName": "All MFG Operations"
  },
  {
    "Category": "Codes",
    "Source": "Marketing Regions",
    "ViewName": "All Marketing Regions"
  },
  {
    "Category": "Codes",
    "Source": "Terms",
    "ViewName": "All Terms"
  },
  {
    "Category": "Codes",
    "Source": "User Codes",
    "ViewName": "All User Codes"
  },
  {
    "Category": "Codes",
    "Source": "Ship Via",
    "ViewName": "All Ship Via"
  },
  {
    "Category": "Codes",
    "Source": "UPC Catalog Groups",
    "ViewName": "All UPC Catalog Groups"
  },
  {
    "Category": "Codes",
    "Source": "Order Status Reasons",
    "ViewName": "All Order Status Reasons"
  },
  {
    "Category": "Codes",
    "Source": "WIP Category",
    "ViewName": "All WIP Category"
  },
  {
    "Category": "Codes",
    "Source": "Discount Reasons",
    "ViewName": "All Discount Reasons"
  },
  {
    "Category": "Codes",
    "Source": "PO Order Types",
    "ViewName": "All PO Order Types"
  },
  {
    "Category": "Codes",
    "Source": "Replenishment Codes",
    "ViewName": "All Replenishment Codes"
  },
  {
    "Category": "Colors",
    "Source": "Colors",
    "ViewName": "All"
  },
  {
    "Category": "Colors",
    "Source": "Colors",
    "ViewName": "Active Colors"
  },
  {
    "Category": "Colors",
    "Source": "Colors",
    "ViewName": "InActive Colors"
  },
  {
    "Category": "Customer Stores",
    "Source": "Stores",
    "ViewName": "Active Ship To/Stores"
  },
  {
    "Category": "Customer Stores",
    "Source": "Stores",
    "ViewName": "Inactive Ship To/Stores"
  },
  {
    "Category": "Customer Stores",
    "Source": "Stores",
    "ViewName": "No Shipment Ship To/Stores"
  },
  {
    "Category": "Customer Stores",
    "Source": "Stores",
    "ViewName": "All Ship To/Stores"
  },
  {
    "Category": "Customers",
    "Source": "Customers",
    "ViewName": "Active Customers"
  },
  {
    "Category": "Customers",
    "Source": "Customers",
    "ViewName": "Inactive Customers"
  },
  {
    "Category": "Customers",
    "Source": "Customers",
    "ViewName": "No Shipment Customers"
  },
  {
    "Category": "Customers",
    "Source": "Customers",
    "ViewName": "All Customers"
  },
  {
    "Category": "Customers",
    "Source": "Customers Balance",
    "ViewName": "Customers With Open Balance"
  },
  {
    "Category": "Customers",
    "Source": "Customers Balance",
    "ViewName": "Customers With Past Due Balance"
  },
  {
    "Category": "Cut Tickets",
    "Source": "Cut Tickets",
    "ViewName": "All Cut Ticket Lines"
  },
  {
    "Category": "Cut Tickets",
    "Source": "Cut Tickets",
    "ViewName": "Open Or Hold Cut Ticket Lines"
  },
  {
    "Category": "Cut Tickets",
    "Source": "Cut Tickets",
    "ViewName": "Closed Cut Ticket Lines"
  },
  {
    "Category": "Cut Tickets",
    "Source": "Cut Tickets",
    "ViewName": "All Cut Tickets"
  },
  {
    "Category": "Cut Tickets",
    "Source": "Cut Tickets",
    "ViewName": "Open Or Hold Cut Tickets"
  },
  {
    "Category": "Cut Tickets",
    "Source": "Cut Tickets",
    "ViewName": "Closed Cut Tickets"
  },
  {
    "Category": "Cut Tickets",
    "Source": "Cut Tickets",
    "ViewName": "Open/hold Cut Line Details by Size"
  },
  {
    "Category": "Cut Tickets",
    "Source": "Cut Tickets",
    "ViewName": "Closed Cut Line Details by Size"
  },
  {
    "Category": "Cut Tickets",
    "Source": "Cut Tickets",
    "ViewName": "Canceled Cut Line Details by Size"
  },
  {
    "Category": "Cut Tickets",
    "Source": "Cut Tickets",
    "ViewName": "All Cut Line Details by Size"
  },
  {
    "Category": "Cut Tickets",
    "Source": "Cut Ticket Receipts",
    "ViewName": "All Cut Ticket Receipts by size"
  },
  {
    "Category": "Cut Tickets",
    "Source": "Cut Ticket Receipts",
    "ViewName": "Cut Ticket Good Receipts by size"
  },
  {
    "Category": "Cut Tickets",
    "Source": "Cut Ticket Receipts",
    "ViewName": "Cut Ticket Damaged Receipts by size"
  },
  {
    "Category": "Cut Tickets",
    "Source": "Cut Ticket Receipts",
    "ViewName": "Year To Date Cut Ticket Receipts by Size"
  },
  {
    "Category": "Cut Tickets",
    "Source": "Cut Ticket Receipts",
    "ViewName": "Last Year To Date Cut Ticket Receipts by Size"
  },
  {

    "Category": "Cut Tickets",
    "Source": "Cut Ticket Receipts",
    "ViewName": "Last 30 Days Cut Ticket Receipts by Size"

  },
  {
    "Category": "Chargebacks",
    "Source": "Chargebacks",
    "ViewName": "Chargebacks having open balance"
  },
  {
    "Category": "Chargebacks",
    "Source": "Chargebacks",
    "ViewName": "Closed chargebacks"
  },
  {
    "Category": "Chargebacks",
    "Source": "Chargebacks",
    "ViewName": "All Chargebacks"
  },
  {
    "Category": "Chargebacks",
    "Source": "Chargebacks",
    "ViewName": "Standalone Chargebacks"
  },
  {
    "Category": "Electronic Data Interchange(EDI)",
    "Source": "3PL Warehouses",
    "ViewName": "Default View"
  },
  {
    "Category": "Electronic Data Interchange(EDI)",
    "Source": "EDI856 Shipments",
    "ViewName": "Pending EDI Shipments"
  },
  {
    "Category": "Electronic Data Interchange(EDI)",
    "Source": "EDI856 Shipments",
    "ViewName": "Completed EDI Shipments"
  },
  {
    "Category": "Electronic Data Interchange(EDI)",
    "Source": "EDI856 Shipments",
    "ViewName": "All EDI Shipments"
  },
  {
    "Category": "Electronic Data Interchange(EDI)",
    "Source": "EDI856 Shipments",
    "ViewName": "Non EDI Shipments"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dyes",
    "ViewName": "All Garment Dye"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dyes",
    "ViewName": "Open Garment Dye"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dyes",
    "ViewName": "Closed Garment Dye"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dyes",
    "ViewName": "All Garment Dye-Line Details"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dyes",
    "ViewName": "Open Garment Dye-Line Details"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dyes",
    "ViewName": "Closed Garment Dye-Line Details"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dyes",
    "ViewName": "Open Garment Dye-Line Details by Size"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dyes",
    "ViewName": "Closed Garment Dye-Line Details by Size"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dyes",
    "ViewName": "Canceled Garment Dye-Line Details by Size"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dyes",
    "ViewName": "All Garment Dye-Line Details by Size"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dye Receipts",
    "ViewName": "All Garment Dye Receipts by Size"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dye Receipts",
    "ViewName": "Garment Dye Good Receipts by Size"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dye Receipts",
    "ViewName": "Garment Dye Damaged Receipts by Size"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dye Receipts",
    "ViewName": "Garment Dye Cancelled Receipts by Size"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dye Receipts",
    "ViewName": "Year To Date Garment Dye Receipts by Size"
  },
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dye Receipts",
    "ViewName": "Last Year To Date Garment Dye Receipts by Size"
  },
   
  {
    "Category": "Garment Dyes",
    "Source": "Garment Dye Receipts",
    "ViewName": "Last 30 Days Garment Dye Receipts by Size"
  },

  {
    "Category": "Intelligent Allocation",
    "Source": "Allocation Batches",
    "ViewName": "All Batches"
  },
  {
    "Category": "Intelligent Allocation",
    "Source": "Allocation Batches",
    "ViewName": "Active Batches"
  },
  {
    "Category": "Intelligent Allocation",
    "Source": "Allocation Batches",
    "ViewName": "InActive Batches"
  },
  {
    "Category": "Intelligent Allocation",
    "Source": "Allocation Profiles",
    "ViewName": "Allocation Profile"
  },
  {
    "Category": "Intelligent Allocation",
    "Source": "Allocations",
    "ViewName": "Order line allocation details by size"

  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "All Invoices"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "All Invoices Including Child Invoices"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Balance Owing"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Zero Balance"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "All Invoices Exclude Voids"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Only voids"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last 30 Days Exclude voids"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last 90 Days Exclude voids"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last 180 Days Exclude voids"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last One Year Exclude voids"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Today"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Yesterday"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Month To Date"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Month"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Quarter"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Quarter To Date"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Year"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Month To Date"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Next 4Weeks"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Next Quarter"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Next Week"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Next Year"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Year To Date"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Year To Date"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Curent Year"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Current Week To Date"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Current Week"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Week"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Week To Date"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Quarter To Date"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Current Month"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Next Month"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Current Quarter"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "All Invoices-Line Details"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Balance Owing-Line Details"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Zero Balance-Line Details"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "All Invoices Exclude Voids-Line Details"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last 30 Days Exclude voids-Line Details"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last 90 Days Exclude voids-Line Details"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last 180 Days Exclude voids-Line Details"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last One Year Exclude voids-Line Details"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Sent 810"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Pending 810"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Revised Pending 810"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Invoice Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Balance Owing-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Zero Balance-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "All Invoices Exclude Voids-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last 30 Days Exclude voids-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last 90 Days Exclude voids-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last 180 Days Exclude voids-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last One Year Exclude voids-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Today-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Yesterday-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Month To Date-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Month-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Quarter-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Quarter To Date-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Year-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Month To Date-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Next 4Weeks-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Next Quarter-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Next Week-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Next Year-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Year To Date-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Year To Date-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Current Year-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Current Week To Date-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Current Week-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Week-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Last Week To Date-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Quarter To Date-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Current Month-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Next Month-Line Details by Size"
  },
  {
    "Category": "Invoices",
    "Source": "Invoices",
    "ViewName": "Current Quarter-Line Details by Size"
  },
  {
    "Category": "Materials",
    "Source": "Materials",
    "ViewName": "Active Materials"
  },
  {
    "Category": "Materials",
    "Source": "Materials",
    "ViewName": "Inactive Materials"
  },
  {
    "Category": "Materials",
    "Source": "Materials",
    "ViewName": "All Materials"
  },
  {
    "Category": "Operators",
    "Source": "Operators",
    "ViewName": "Active Operators"
  },
  {
    "Category": "Operators",
    "Source": "Operators",
    "ViewName": "InActive Operators"
  },
  {
    "Category": "Operators",
    "Source": "Operators",
    "ViewName": "All Operators"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Open/Hold Orders(ALL)"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Open/Hold Orders(Excluding Automation)"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Open/Hold Orders(Automation Only)"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Pre-auth Failed Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Open/Hold Orders with Allocation"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Open/Hold Orders with MPT Name"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Open Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Hold Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Canceled Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Completed Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "All Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "All Orders with MPT Name"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Bulk Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "EDI Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Weblink Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Easyshop Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Remotelink Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Current Month Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Overdue Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Current Quarter Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Upto Next Month Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Upto Next 90 Days Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Orders With Multiple Pick Tickets"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Today"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Current Week"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Current Week To Date"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Current Quarter"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Current Year"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Month To Date"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Quarter To Date"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Year To Date"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Yesterday"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Last Week"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Last Week To Date"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Last Month To Date"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Last Quarter"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Last Quarter To Date"
  },
   
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Last Year"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Last Year To Date"
  },

  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Next Week"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Next 4Weeks"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Next Month"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Next Quarter"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Next Year"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Orders Due Next Month"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Orders Due This Month"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Orders Created This Month"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Orders Created Last Month"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Open/Hold-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Open/Hold-Line Details with Allocation"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Open Orders-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Hold Orders-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Completed Orders-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Canceled Orders-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "All Orders-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Bulk Orders-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "EDI Orders-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Weblink Orders-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Easyshop Orders-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Remotelink Orders-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Current Month Orders-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Overdue Orders-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Current Quarter Orders-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Upto Next Month Orders-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Upto Next 90 Days Orders-Line Details"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Open/Hold Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Completed Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Canceled Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "All Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Current Month Orders-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Overdue Orders-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Current Quarter Orders-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Upto Next Month Orders-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Upto Next 90 Days Orders-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Today-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Current Week-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Current Week To Date-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Current Quarter-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Current Year-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Month To Date-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Quarter To Date-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Year To Date-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Yesterday-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Last Week-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Last Week To Date-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Last Month To Date-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Last Quarter-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Last Quarter To Date-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Last Year-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Last Year To Date-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Next Week-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Next 4Weeks-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Next Month-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Next Quarter-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Next Year-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Orders Due This Month-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Orders Due Next Month-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Orders Created This Month-Order Line Details by Size"
  },
  {
    "Category": "Orders",
    "Source": "Orders",
    "ViewName": "Orders Created Last Month-Order Line Details by Size"
  },

  {
    "Category": "Orders",
    "Source": "Orders Lines",
    "ViewName": "Open Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders Lines",
    "ViewName": "Open/Hold Order Lines(Excluding Automation)"
  },
  {
    "Category": "Orders",
    "Source": "Orders Lines",
    "ViewName": "Open/Hold Order Lines(Automation Only)"
  },
  {
    "Category": "Orders",
    "Source": "Orders Lines",
    "ViewName": "Open/Hold Order Lines(ALL)"
  },
  {
    "Category": "Orders",
    "Source": "Orders Lines",
    "ViewName": "Hold Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders Lines",
    "ViewName": "Completed Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders Lines",
    "ViewName": "All Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders Lines",
    "ViewName": "Canceled Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders Lines",
    "ViewName": "Bulk Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders Lines",
    "ViewName": "EDI Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders Lines",
    "ViewName": "Weblink Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders Lines",
    "ViewName": "Easyshop Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders Lines",
    "ViewName": "Remotelink Orders"
  },
  {
    "Category": "Orders",
    "Source": "Orders With Pick Tickets",
    "ViewName": "Open/Hold Orders With PickTickets"
  },
  {
    "Category": "Orders",
    "Source": "Orders With Pick Tickets",
    "ViewName": "Open/Hold Orders W/O PickTickets"
  },
  {
    "Category": "Orders",
    "Source": "Orders With Pick Tickets",
    "ViewName": "Open Orders With PickTickets"
  },
  {
    "Category": "Orders",
    "Source": "Orders With Pick Tickets",
    "ViewName": "Open Orders W/O PickTickets"
  },
  {
    "Category": "Orders",
    "Source": "Orders With Pick Tickets",
    "ViewName": "Hold Orders With PickTickets"
  },
  {
    "Category": "Orders",
    "Source": "Orders With Pick Tickets",
    "ViewName": "Hold Orders W/O PickTickets"
  },
  {
    "Category": "Orders",
    "Source": "Orders With Pick Tickets",
    "ViewName": "All Orders With PickTickets"
  },
  {
    "Category": "Orders",
    "Source": "Orders With Pick Tickets",
    "ViewName": "All Orders W/O PickTickets"
  },
  {
    "Category": "Payment Reconciliation",
    "Source": "Payment Reconciliation Batches",
    "ViewName": "Active Batches"
  },
  {
    "Category": "Payment Reconciliation",
    "Source": "Payment Reconciliation Batches",
    "ViewName": "Closed Batches"
  },
  {
    "Category": "Payment Reconciliation",
    "Source": "Payment Reconciliation Batches",
    "ViewName": "Voided Batches"
  },
  {
    "Category": "Payment Reconciliation",
    "Source": "Payment Reconciliation Batches",
    "ViewName": "All Batches"
  },
  {
    "Category": "Payment Reconciliation",
    "Source": "Payment Reconciliation Batches",
    "ViewName": "Batches having open balance"
  },
  {
    "Category": "Payment Reconciliation",
    "Source": "Payment Reconciliation Batches",
    "ViewName": "Batches having open chargebacks"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "All Pick Tickets"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Open(Status=O)(ALL)"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Open(Status=O)(Excluding Automation)"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Open(Status=O)(Automation Only)"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Shipped(Status=S)(ALL)"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Shipped(Status=S)(Excluding Automation)"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Shipped(Status=S)(Automation Only)"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Packed(Status=P)"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Never Printed"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Printed but not invoiced"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Shipping Data Received"
  },

  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Open Pick Tickets By Style Color"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Packed Pick Tickets By Style Color"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Shipped Pick Tickets By Style Color"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Open Pick Tickets By Style Color Size"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Shipped Pick Tickets By Style Color Size"
  },
  {
    "Category": "Pick Tickets",
    "Source": "Pick Tickets",
    "ViewName": "Packed Pick Tickets By Style Color Size"
  },

  {
    "Category": "Production Tracking",
    "Source": "Production Jobs",
    "ViewName": "All Productions"
  },
  {
    "Category": "Production Tracking",
    "Source": "Production Jobs",
    "ViewName": "Open Productions"
  },
  {
    "Category": "Production Tracking",
    "Source": "Production Jobs",
    "ViewName": "Hold Productions"
  },
  {
    "Category": "Production Tracking",
    "Source": "Production Jobs",
    "ViewName": "Completed Productions"
  },
  {
    "Category": "Production Tracking",
    "Source": "Production Jobs",
    "ViewName": "Canceled Productions"
  },
  {
    "Category": "Production Tracking",
    "Source": "Production Jobs",
    "ViewName": "Open Production Operations"
  },
  {
    "Category": "Production Tracking",
    "Source": "Production Jobs",
    "ViewName": "Completed Production Operations"
  },
  {
    "Category": "Production Tracking",
    "Source": "Production Jobs",
    "ViewName": "Open Production Operations by Size"
  },
  {
    "Category": "Production Tracking",
    "Source": "Production Jobs",
    "ViewName": "Completed Production Operations by Size"
  },
  {
    "Category": "Production Tracking",
    "Source": "Production Jobs",
    "ViewName": "All Production Operations by Size"
  },
  {
    "Category": "Production Tracking",
    "Source": "Production Jobs",
    "ViewName": "All Production Operations"
  },

  {
    "Category": "Production Tracking",
    "Source": "Production Templates",
    "ViewName": "All Templates"
  },
  {
    "Category": "Production Tracking",
    "Source": "Production Templates",
    "ViewName": "Active Templates"
  },
  {
    "Category": "Production Tracking",
    "Source": "Production Templates",
    "ViewName": "InActive Templates"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "Open PO's"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "Closed PO's"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "Canceled PO's"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "All PO's"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "Style PO's"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "Material PO's"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "Open PO's-Line Details"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "Closed PO's-Line Details"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "Canceled PO's-Line Details"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "All PO's-Line Details"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "Style PO's-Line Details"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "Material PO's-Line Details"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "Open PO's-Line Details by Size"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "Closed PO's-Line Details by Size"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "Canceled PO's-Line Details by Size"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Orders",
    "ViewName": "All PO's-Line Details by Size"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Order Receipts",
    "ViewName": "All Purchase Order Receipts by Size"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Order Receipts",
    "ViewName": "Purchase Order Good Receipts by Size"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Order Receipts",
    "ViewName": "Purchase Order Damaged Receipts by Size"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Order Receipts",
    "ViewName": "Purchase Order Cancelled Receipts by Size"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Order Receipts",
    "ViewName": "Year To Date Purchase Order Receipts by Size"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Order Receipts",
    "ViewName": "Last Year To Date Purchase Order Receipts by Size"
  },
  {
    "Category": "Purchase Orders",
    "Source": "Purchase Order Receipts",
    "ViewName": "Last 30 Days Purchase Order Receipts by Size"
  },
  {
    "Category": "Returns",
    "Source": "RMA and Credit Memos",
    "ViewName": "Open RMA"
  },
  {
    "Category": "Returns",
    "Source": "RMA and Credit Memos",
    "ViewName": "Credit Memo Issued"
  },
  {
    "Category": "Returns",
    "Source": "RMA and Credit Memos",
    "ViewName": "Void"
  },
  {
    "Category": "Returns",
    "Source": "RMA and Credit Memos",
    "ViewName": "All"
  },
  {
    "Category": "Sales Reps",
    "Source": "SalesReps",
    "ViewName": "Active SalesRep"
  },
  {
    "Category": "Sales Reps",
    "Source": "SalesReps",
    "ViewName": "Inactive SalesRep"
  },
  {
    "Category": "Sales Reps",
    "Source": "SalesReps",
    "ViewName": "All Status"
  },
  {
    "Category": "Sales Reps",
    "Source": "SalesReps Commision",
    "ViewName": "Sales Commissions"
  },
  {
    "Category": "Shipments",
    "Source": "Shipments",
    "ViewName": "Open Shipments"
  },
  {
    "Category": "Shipments",
    "Source": "Shipments",
    "ViewName": "Finalized Shipments"
  },
  {
    "Category": "Shipments",
    "Source": "Shipments",
    "ViewName": "Void Shipments"
  },
  {
    "Category": "Shipments",
    "Source": "Shipments",
    "ViewName": "Pending EDI753 Shipments"
  },
  {
    "Category": "Shipments",
    "Source": "Shipments",
    "ViewName": "Pending EDI754 Shipments"
  },
  {
    "Category": "Shipments",
    "Source": "Shipments",
    "ViewName": "Shipments Created Via EDI754"
  },
  {
    "Category": "Shipments",
    "Source": "Shipments",
    "ViewName": "Pending 856Shipments"
  },
  {
    "Category": "Shipments",
    "Source": "Shipments",
    "ViewName": "All Shipments"
  },
  {
    "Category": "Shipments",
    "Source": "Shipping Templates",
    "ViewName": "Active Shipping Templates"
  },
  {
    "Category": "Shipments",
    "Source": "Shipping Templates",
    "ViewName": "In-Active Shipping Templates"
  },
  {
    "Category": "Shipments",
    "Source": "Shipping Templates",
    "ViewName": "All Shipping Templates"
  },
  {
    "Category": "Styles",
    "Source": "Styles",
    "ViewName": "Active Styles"
  },
  {
    "Category": "Styles",
    "Source": "Styles",
    "ViewName": "Inactive Styles"
  },
  {
    "Category": "Styles",
    "Source": "Styles",
    "ViewName": "All Styles"
  },
  {
    "Category": "Styles",
    "Source": "Styles",
    "ViewName": "Active Inventory Styles"
  },
  {
    "Category": "Styles",
    "Source": "Styles",
    "ViewName": "Inactive Inventory Styles"
  },
  {
    "Category": "Styles",
    "Source": "Styles",
    "ViewName": "Active Non-Inventory Styles"
  },
  {
    "Category": "Styles",
    "Source": "Styles",
    "ViewName": "Inactive Non-Inventory Styles"
  },
  {
    "Category": "Styles",
    "Source": "Styles",
    "ViewName": "Active Service Styles"
  },
  {
    "Category": "Styles",
    "Source": "Styles",
    "ViewName": "Inactive Service Styles"
  },
  {
    "Category": "Styles",
    "Source": "Styles",
    "ViewName": "Styles with Replenishment Codes"
  },
  {
    "Category": "Styles",
    "Source": "Styles UPC",
    "ViewName": "UPC Browse"
  },
  {
    "Category": "Styles",
    "Source": "Styles UPC",
    "ViewName": "Without UPC Numbers"
  },
  {
    "Category": "Styles",
    "Source": "Styles UPC",
    "ViewName": "With UPC Numbers"
  },
  {
    "Category": "Styles",
    "Source": "Styles UPC",
    "ViewName": "With Released UPC Numbers"
  },
  {
    "Category": "Styles",
    "Source": "Styles UPC",
    "ViewName": "With Expired UPC Numbers"
  },
  {
    "Category": "Styles",
    "Source": "Styles UPC",
    "ViewName": "All Styles"
  },
  {
    "Category": "Vendors",
    "Source": "Vendors",
    "ViewName": "Active"
  },
  {
    "Category": "Vendors",
    "Source": "Vendors",
    "ViewName": "Inactive"
  },
  {
    "Category": "Vendors",
    "Source": "Vendors",
    "ViewName": "All"
  },
  {
    "Category": "Warehouses",
    "Source": "Warehouses",
    "ViewName": "All WareHouses"
  },
  {
    "Category": "Warehouses",
    "Source": "Warehouses",
    "ViewName": "Active WareHouses"
  },
  {
    "Category": "Warehouses",
    "Source": "Warehouses",
    "ViewName": "InActive WareHouses"
  },
  {
    "Category": "Warehouses",
    "Source": "Warehouse Inventory Details",
    "ViewName": "Style/Inventory Items"
  },
  {
    "Category": "Warehouses",
    "Source": "Warehouse Inventory Details",
    "ViewName": "Style/Inventory Items With OTS(i)"
  },
  {
    "Category": "Warehouses",
    "Source": "Warehouse Inventory Details",
    "ViewName": "Style/Non-Inventory Items"
  },
  {
    "Category": "Warehouses",
    "Source": "Warehouse Inventory Details",
    "ViewName": "Style/Service Items"
  },
  {
    "Category": "Warehouses",
    "Source": "Warehouse Inventory Details by Size",
    "ViewName": "Style/Inventory Items"
  },
  {
    "Category": "Warehouses",
    "Source": "Warehouse Inventory Details by Size",
    "ViewName": "Style/Inventory Items With OTS(i)"
  },
  {
    "Category": "Warehouses",
    "Source": "Warehouse Inventory Details by Size",
    "ViewName": "Style/Non-Inventory Items"
  },
  {
    "Category": "Warehouses",
    "Source": "Warehouse Inventory Details by Size",
    "ViewName": "Style/Service Items"
  },
  {
    "Category": "Warehouses",
    "Source": "Inventory Transfer Jobs",
    "ViewName": "Open Transfers"
  },
  {
    "Category": "Warehouses",
    "Source": "Inventory Transfer Jobs",
    "ViewName": "Closed Transfers"
  },
  {
    "Category": "Warehouses",
    "Source": "Inventory Transfer Jobs",
    "ViewName": "All Transfers"
  },
  {
    "Category": "Warehouses",
    "Source": "Inventory Transfer Jobs",
    "ViewName": "Void Transfers"
  },
  {
    "Category": "Web Orders",
    "Source": "Web Orders",
    "ViewName": "All Web Orders"
  },
  {
    "Category": "Web Orders",
    "Source": "Web Orders",
    "ViewName": "WebLink Orders"
  },
  {
    "Category": "Web Orders",
    "Source": "Web Orders",
    "ViewName": "RemoteLink Orders"
  },
  {
    "Category": "Web Orders",
    "Source": "Web Orders",
    "ViewName": "EasyShop Orders"
  },
  {
    "Category": "Web Orders",
    "Source": "Web Orders",
    "ViewName": "Rejected Orders"
  },
  {
    "Category": "Web Orders",
    "Source": "Web Orders",
    "ViewName": "Pending Import Orders"
  },
  {
    "Category": "Web Orders",
    "Source": "Web Orders",
    "ViewName": "Weblink Pending Import Orders"
  },
  {
    "Category": "Web Orders",
    "Source": "Web Orders",
    "ViewName": "Remotelink Pending Import Orders"
  },
  {
    "Category": "Web Orders",
    "Source": "Web Orders",
    "ViewName": "EasyShop Pending Import Orders"
  },
  {
    "Category": "Web Orders",
    "Source": "Web Orders",
    "ViewName": "Pending Weblink Approvals"
  },
  {
    "Category": "Shopify",
    "Source": "Shopify",
    "ViewName": "Shopify Stores"
  },
  {
    "Category": "Shopify",
    "Source": "Shopify Variants",
    "ViewName": "Shopify Variant Details"
  }
]