import { CButton } from '@coreui/react';
import React from 'react';

const CopyButton = ({ textToCopy, displayText,color }) => {
  const [status, setStatus] = React.useState("fa-solid fa-copy")
  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        console.log('Text copied to clipboard');
        setStatus("fa-solid fa-clipboard-check text-success");
      })
      .catch((error) => {
        console.error('Failed to copy text: ', error);
      });
  };

  return (
    <CButton color={color} onClick={copyToClipboard} onBlur={()=>{setStatus("fa-solid fa-copy")}}>{displayText} <i className={status}></i></CButton>
  );
};

export default CopyButton;
