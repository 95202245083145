const storeUserDetailsToLocalStorage = (value) => {
    localStorage.setItem('UserDetails', JSON.stringify(value));
    sessionStorage.setItem('UserDetails', JSON.stringify(value));
};


const getUserDetailsFromLocalStorage = () => {
    let userInfo =  localStorage.getItem('UserDetails');
    return userInfo ? JSON.parse(userInfo) : {};
};

const getTokenFromLocalStorage = () => {
    let userInfo = localStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo) : {};
    if (userInfo != null) {
        return userInfo.UserToken;
    } else {
        return null;
    }

}

const getZoneFromLocalStorage = () => {
    let userInfo = localStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {}
    if (userInfo != null) {
        return userInfo.Zone;
    } else {
        return null;
    }

}

const getAppVersionFromLocalStorage = () => {
    let userInfo = localStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {}
    if (userInfo != null) {
        return userInfo.AppVersion;
    } else {
        return null;
    }
}

const getUserAccountFromLocalStorage = () => {
    let userInfo = localStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {}
    if (userInfo != null) {
        return userInfo.UserAccount;
    } else {
        return null;
    }
}

const getUserLoginAccountFromLocalStorage = () => {
    let userInfo = localStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {}
    if (userInfo != null) {
        return userInfo?.LoginAccountCode;
    } else {
        return null;
    }
}

const getUserLoginPrefixFromLocalStorage = () => {
    let userInfo = localStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {}
    if (userInfo != null) {
        return userInfo?.LoginAccountCode;
    } else {
        console.log(userInfo?.LoginAccountCode);
        return null;
    }
}




const getUserNameFromLocalStorage = () => {
    let userInfo = localStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {}
    if (userInfo != null) {
        return userInfo.UserAccount;
    } else {
        console.log(userInfo?.UserAccount);
        return null;
    }
}
const getUserEmailFromLocalStorage = () => {
    let userInfo = localStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {};
    if (userInfo != null) {
        return userInfo.UserName;
    } else {
        return null;
    }
}

const getUserIdFromLocalStorage = () => {
    let userInfo = localStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {};
    if (userInfo != null) {
        return userInfo.UserId;
    } else {
        return null;
    }
}

const getUserEmailFromSessionStorage = () => {
    let userInfo = sessionStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {};
    if (userInfo != null) {
        return userInfo.UserName;
    } else {
        return null;
    }
}

const getUserLoginPrefixFromSessionStorage = () => {
    let userInfo = sessionStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {}
    if (userInfo != null) {
        return userInfo?.LoginAccountCode;
    } else {
        console.log(userInfo?.LoginAccountCode);
        return null;
    }
}

const getUserIdFromSessionStorage = () => {
    let userInfo = sessionStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {};
    if (userInfo != null) {
        return userInfo.UserId;
    } else {
        return null;
    }
}

const setWarehouseDetails = (object) => {
    localStorage.setItem('warehouseData', JSON.stringify(object))
}

const getWarehouseDetails = () => {
    let sourcewh = localStorage.getItem('warehouseData')
    let warehousedetails = sourcewh ? JSON.parse(sourcewh) : {};
    return warehousedetails;
}

const getUserLoggedInURLFromLocalStorage = () => {
    let userInfo = localStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {};
    if (userInfo?.LoginURL) {
        return userInfo.LoginURL;
    } else {
        return null;
    }

}

const deleteUserDetails = () => {
    localStorage.removeItem('UserDetails');
}

const getUserFLnameFromLocalStorage = () => {
    let userInfo = localStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {};
    if (userInfo?.Firstname || userInfo?.LastName) {
        return userInfo?.Firstname + " " + userInfo?.LastName;
    } else {
        return null;
    }
}
const getUserFirstnameFromLocalStorage = () => {
    let userInfo = localStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {};
    if (userInfo?.Firstname) {
        return userInfo?.Firstname
    } else {
        return null;
    }
}
const getUserLastnameFromLocalStorage = () => {
    let userInfo = localStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {} ;
    if (userInfo?.LastName) {
        return userInfo?.LastName
    } else {
        return null;
    }
}

const getUserAccessPermissionFromLocalStorage = () => {
    let userInfo = localStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): [] ;
    if (userInfo?.access?.permission) {
        return userInfo?.access?.permission
    } else {
        return null;
    }
}

const inaccessibleList = () => {
    let userInfo = localStorage.getItem('UserDetails');
    userInfo = userInfo ? JSON.parse(userInfo): {} ;
    if (userInfo != null) {
        let userAccess = userInfo?.access?.permission;
        let inaccessible = [];
        return handleInaccessibleList(userAccess, inaccessible);
    } else {
        return null;
    }
}

const handleInaccessibleList = (userAccess, inaccessible) => {
    if (userAccess?.length > 0) {
        userAccess?.forEach((ele) => {
            let myData = {};
            if (ele.access === "Disabled") {
                myData = {
                    menuCode: ele?.code.toLowerCase(),
                    menuName: SecretCodeForRoutes[ele?.code]
                }
                inaccessible.push(myData)
            }
        })
    }
    return inaccessible;
}

const GetApplicationToken = () => {
    return localStorage.getItem('ApplicationToken');
}


const GetSystemSettings = () => {
    let userSettingsData = localStorage.getItem('userSettingsData');
    let userSystemSetting = userSettingsData ? JSON.parse(userSettingsData): {};
    return userSystemSetting;
}


const SecretCodeForRoutes = {
    AIMS360WMSADJ01: "Adjustment",
    AIMS360MCSM01: "Sync Manually",
    AIMS360MCSD01: "Sync Data",
    AIMS360MCS01: "Settings",
    AIMS360MCSJ01: "Sync Jobs",
    AIMS360WMSPJ01: "Production Jobs",
    AIMS360WMSINQ01: "Inquiry",
    AIMS360WMST01: "Transfer",
    AIMS360WMSPKT01: "Pick Tickets",
    AIMS360OTSBD01: "OTS By Date",
    AIMS360WMSREC01: "Receiving",
    AIMS360WMSCTE01: "Cut Ticket Edit",
    AIMS360RWSR01: "User Roles",
    AIMS360RWSU01: "Users",
    AIMS360RWSW01: "Warehouse Setting",
    AIMS360RWAVM01: "Allow manual override scan to verify",
    AIMS360OTSAC01: "Create Job",
    AIMS360OTSAJ01: "job list",
    AIMS360WMSGD01: "Garment Dye",
    AIMS360LRS01: "Settings",
    AIMS360LROS01: "Order Status",
    AIMS360IFI01: "File Import",
    AIMS360IIS01: "Import Status",
    AIMS360IID01: "Import Details",
    AIMS360STYLES01 : "Styles",
    AIMS360RWUS01 : "User Settings",
    AIMS360RWSS01 : "System Settings",
    AIMS360RWSG01 : "Term Code Mapping",
    AIMS360RWSAGE01 : "Sage 100",
    AIMS360WMSWT01:"Open Transfers",
    AIMS360RWCFD01 :"Fields List",
    AIMS360RWACBL : "Allow Change the bin location",
    AIMS360CPLOSANWFTP01: "Wholesale Upload to FTP",
    AIMS360RWOD01: "Orders",
    AIMS360RWWHT01: "Warehouse Transfers",
    AIMS360RWRMA01: "RMAs",
    AIMS360RWINVS  : "Invoices",
    AIMS360IMPORT : "Import Jobs",
    AIMS360AIMSCTRS:"AIMS360 Customers",
    AIMS360QBCTRS:"QuickBooks Customers",
    AIMS360RWJOBS01 : "My Runway Jobs",
    AIMS360CUST01 : "Active Customers List",
    AIMS360ORDERS01: "Open/Hold Orders",
    AIMS360VENDORPO:"Open Vender POs",
    AIMS360VPOBS01:"Vendor PO Update Details",
    AIMS360OBS01:"Order Update Details",
    AIMS360WD01:"Warehouse Details",
    AIM360AQSCH01:"Aqua Job List",
    AIM360AQJBHSH01:'Job Run History'
}

  function parseSettingValue(value, type) {
    switch (type) {
      case "Boolean":
        return value.toLowerCase() === "true";
      case "String":
        return value.toLowerCase() === "";
      case "Number":
        return value.toLowerCase() === "0";
      // Add more cases for other types if needed
      default:
        return value;
    }
  }

  function getSettingValue(settingsArray, settingKey, defaultValue = null) {
    const setting = settingsArray?.find((item) => item?.settingKey?.toLowerCase() === settingKey.toLowerCase());
    return setting
      ? parseSettingValue(setting?.settingValue, setting?.type)
      : defaultValue;
  }


export {
    getWarehouseDetails,
    setWarehouseDetails,
    storeUserDetailsToLocalStorage,
    getUserDetailsFromLocalStorage,
    getTokenFromLocalStorage,
    getZoneFromLocalStorage,
    getUserAccountFromLocalStorage,
    getUserNameFromLocalStorage,
    getUserLoggedInURLFromLocalStorage,
    deleteUserDetails,
    getAppVersionFromLocalStorage,
    getUserFLnameFromLocalStorage,
    getUserLoginAccountFromLocalStorage,
    getUserEmailFromLocalStorage,
    getUserIdFromLocalStorage,
    getUserLoginPrefixFromLocalStorage,
    inaccessibleList,
    GetApplicationToken,
    getUserFirstnameFromLocalStorage,
    getUserLastnameFromLocalStorage,
    getUserLoginPrefixFromSessionStorage,
    GetSystemSettings,
    getUserIdFromSessionStorage,
    SecretCodeForRoutes,
    getUserAccessPermissionFromLocalStorage,
    getSettingValue,
    getUserEmailFromSessionStorage
}
