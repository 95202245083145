const AddSpinner =()=>{
    let elemDiv = document.createElement('div');
    elemDiv.setAttribute('id', 'outer')
    elemDiv.style.cssText = 'position:fixed;top:0%;width:100%;height:100vh;opacity:0.5;z-index:9999;background:#000; align-items: center; display: flex;justify-content: center;';
    document.body.appendChild(elemDiv);

    let elemDiv1 = document.createElement('div');
    elemDiv1.setAttribute("class", "spinner-border text-info");
    elemDiv.appendChild(elemDiv1);

}

const RemoveSpinner = ()=>{
    try{
        let outer = document.getElementById("outer");
        document.body.removeChild(outer);
    }catch(err){
        console.log(err)
    }
}

const renderSpinnerIcon = () => {
    return (<i className="fas fa-spinner fa-spin"></i>)
  }

export{AddSpinner, RemoveSpinner,renderSpinnerIcon}