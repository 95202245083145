import { CButton, CCard } from "@coreui/react";
import { useState } from "react";
import GridSidebar from "./grid-sidebar";
import { useSelector } from "react-redux";
//GridSelectionPanel
function SelectionFields({
  layoutUpdated,
  getSelectedTabs,
  gridRef,
  gridStateRef,
  activeTab,
  views,
  setViews,
  ModuleMenuCode,
  gridData,
  excludedKeys,
  updatingStatesToEmpty,
  primaryKeys,
  removingExcessData,
  viewsWithTabs,
  viewsTabs,
  expandCheck1,
  defaultTab,
  expandValue1,
  getUpdatedViews,
  HeaderKeyMappings,
  FilterActionLayoutInit,
  gridCheckBox,
  getFilterClicks,
  isUserHasOnlyReadAccess,
  setIsLoading,
  DynamicFilters=[],
  excludeFilterColumnKeys="",
  gridColumns=[],
  filterOperatorsNumberKeysOnly=[],
  previewView=null,
}) {
  const list = [
    { label: "Views", value: "views", disabled: false },
    { label: "Filters", value: "filters", disabled: false },
    // { label: "Grid View", value: "gridView", disabled: true },
  ];
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarProblem, setSidebarProblem] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const darkMode = useSelector((state) => state.darkMode);
  const handleGridView = (value) => {
    setSidebarProblem(true);
    setSidebarOpen(true);
    setSelectedValue(value);
  };
  return (
    <div>
      <CCard borderColor="black mr-4">
        <div className="d-flex flex-column grid-side-list">
            {list?.map((ele) => (
              <CButton
                onClick={() => handleGridView(ele.value)}
                className={`text-nowrap d-flex py-0 rounded-0 viewFields ${
                  !darkMode && "text-primary"
                }`}
                key={ele.value}
                disabled={ele.disabled}
              >
                <div className="text-left w-75">{ele.label}</div>
                <div className="w-25">&gt;</div>
              </CButton>
            ))}
          </div>
      </CCard>
      <GridSidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        selectedValue={selectedValue}
        getSelectedTabs={getSelectedTabs}
        layoutUpdated={layoutUpdated}
        gridRef={gridRef}
        gridStateRef={gridStateRef}
        activeTab={activeTab}
        views={views}
        setViews={setViews}
        ModuleMenuCode={ModuleMenuCode}
        gridData={gridData}
        excludedKeys={excludedKeys}
        updatingStatesToEmpty={updatingStatesToEmpty}
        primaryKeys={primaryKeys}
        sidebarProblem={sidebarProblem}
        removingExcessData={removingExcessData}
        viewsWithTabs = {viewsWithTabs}
        viewsTabs = {viewsTabs}
        expandCheck1 = {expandCheck1}
        defaultTab = {defaultTab}
        expandValue1 = {expandValue1}
        getUpdatedViews={getUpdatedViews}
        HeaderKeyMappings={HeaderKeyMappings}
        FilterActionLayoutInit={FilterActionLayoutInit}
        gridCheckBox={gridCheckBox}
        getFilterClicks={getFilterClicks}
        isUserHasOnlyReadAccess={isUserHasOnlyReadAccess}
        setIsLoading={setIsLoading}
        DynamicFilters={DynamicFilters}
        excludeFilterColumnKeys={excludeFilterColumnKeys}
        gridColumns={gridColumns}
        filterOperatorsNumberKeysOnly={filterOperatorsNumberKeysOnly}
        previewView={previewView}
      />
    </div>
  );
}

export default SelectionFields;
