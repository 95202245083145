import React from "react";
import { CNav, CNavItem, CNavLink } from "@coreui/react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const TabLinks = ({
  tabLinks,
  activeTab,
  darkMode,
  TabClassNames,
  onTabClick,
  views,
  handleTabClose,
  setTabLinks,
}) => {
  const handleTabDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(tabLinks, result.source.index, result.destination.index);
    setTabLinks(items);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  return (
    <CNav
      variant="tabs"
      className={`${
        !darkMode && "tab-background"
      } flex-nowrap text-nowrap ${TabClassNames}`}
    >
      <DragDropContext onDragEnd={handleTabDragEnd}>
        <Droppable droppableId="tabs" direction="horizontal">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="d-flex"
              style={{height: "30px"}}
            >
              {tabLinks?.map((ele, index) => (
                <Draggable
                  key={ele?.name + index}
                  draggableId={index.toString()}
                  index={index}
                  shouldRespectForcePress
                  isDragDisabled={snapshot.isDraggingOver}
                  onBeforeCapture={(beforeCapture) => {
                    const mouseY = beforeCapture.clientY;
                    const rowRect =
                      beforeCapture.draggable.parentElement.getBoundingClientRect();
                    const rowTop = rowRect.top;
                    const rowBottom = rowRect.bottom;

                    if (mouseY < rowTop || mouseY > rowBottom) {
                      beforeCapture.preventDefault();
                    }
                  }}
                >
                  {(provided, snapshot, drag) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <CNavItem key={ele.name} className="d-flex">
                        <CNavLink
                          data-tab={ele?.name}
                          onClick={() => { onTabClick(ele) }}
                          className={`${
                            activeTab?.name !== ele?.name
                              ? `${
                                  darkMode
                                    ? "dark-nav-link-inActive"
                                    : "nav-link-inActive"
                                } pb-1`
                              : darkMode && "dark-nav-link-Active"
                          } p-1 px-2`}
                          active={activeTab?.name === ele?.name}
                        >
                          <div className="d-flex align-items-center ">
                            <div>{ele?.name}</div>
                            {activeTab?.name === ele?.name &&
                              tabLinks?.length > 1 && (
                                <i
                                  className="fa fa-close pl-2"
                                  onClick={() => handleTabClose(ele?.name)}
                                ></i>
                              )}
                          </div>
                        </CNavLink>
                        {views?.length > 1 && (
                          <span className="d-flex align-items-center text-secondary tab-separator">
                            |
                          </span>
                        )}
                      </CNavItem>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </CNav>
  );
};

export default TabLinks;