import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import App from './App';
import "react-datepicker/dist/react-datepicker.css";
import 'jquery/dist/jquery.min.js';
import store from './store'
import './index.css'
import { icons } from './assets/icons'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
import 'react-photo-view/dist/react-photo-view.css';

React.icons = icons

ReactDOM.render(
  <React.StrictMode>
  <Provider store={store}>
    <App/>
  </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA