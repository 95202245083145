import axios from "axios";
import { storeUserDetailsToLocalStorage } from "../../Services/LocalStorageService.jsx";

export { AquaScheduler } from "./Aqua Scheduler/CreateScheduler/AquaScheduler.jsx";
export { JobList } from "./Aqua Scheduler/AquaJobList/aquaJobList.jsx"
export { EditDetails } from "./Aqua Scheduler/AquaJobList/EditJobDetails.jsx"
export { JobHistory } from "./Aqua Scheduler/AquaJobList/JobListHistory.jsx"

const Authenticate = () => {
    const params = new URLSearchParams(window?.location?.href);
    const token = params.get("token");
    const zone = params.get("zone");
    const loginAccountCode = params.get("loginprefix");
    
    let userDetails = {};
    
    if (token && zone) {
        const isEastZone = zone?.toLowerCase() === "east";
        const apiUrl = isEastZone ? `${process.env.REACT_APP_LOGIN_API_URL_East}` : `${process.env.REACT_APP_LOGIN_API_URL_West}`;
        const functionKey = isEastZone ? `${process.env.REACT_APP_LOGIN_EAST_FUNCTION_KEY}` : `${process.env.REACT_APP_LOGIN_WEST_FUNCTION_KEY}`;

        const payload = JSON.stringify({
            securityCode: token,
            grant_type: "token_details"
        });

        axios.post(apiUrl, payload, { headers: { "x-functions-key": functionKey } })
            .then((res) => {
                console.log(res.data);

                userDetails = {
                    UserName: res.data?.userName,
                    UserAccount: res.data?.account,
                    UserToken: res.data?.accessToken,
                    Zone: res.data?.zone,
                    Firstname: res.data?.userFirstName,
                    LastName: res.data?.userLastName,
                    UserId: res.data?.userId,
                    ClientId: res.data?.clientID,
                    CompanyName: res.data?.companyName,
                    Database: res.data?.databaseName,
                    LoginAccountCode: loginAccountCode
                };
                storeUserDetailsToLocalStorage(userDetails);
                localStorage.setItem("userSettingsData", JSON.stringify(res?.data?.userSettings));

                const settingURL = isEastZone ? `${process.env.REACT_APP_SETTINGS_EAST}/UserSettings` : `${process.env.REACT_APP_SETTINGS_WEST}/UserSettings`;
                const data = JSON.stringify({
                    "requestType": "getUserSettings",
                    "moduleType": "userData"
                });

                axios.post(settingURL, data, {
                    headers: {
                        "Authorization": `Bearer ${res.data?.accessToken}`
                    }
                }).then((res) => {
                    if (res.status === 200) {
                        let defaultTimeZone = res?.data?.payload?.MomentTimezone || 'PST8PDT';
                        let clientTimeZone = res?.data?.payload?.TimeZone || "Pacific Standard Time";
                        localStorage.setItem("clientTimeZone", clientTimeZone);
                        localStorage.setItem('defaultTimeZone', defaultTimeZone);
                    }
                }).catch((e) => {
                    console.log(e);
                });
            });
    }
};


export { Authenticate };