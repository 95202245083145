import { MobileContent, MobileMenuLables } from "./ContentList/MobileContent";
import { DesktopContent, DesktopMenuLables } from "./ContentList/DesktopContent";
import { DesktopStyle } from "./ContentStyle/DesktopStyle";
import { MobileStyle } from "./ContentStyle/MobileStyle";

const viewWidth = window.innerWidth;
const breakpoint = 650;
let values = {};
let style = {};
let menuNames = {};
values = viewWidth < breakpoint ? MobileContent() : DesktopContent();
style = viewWidth < breakpoint ? MobileStyle() : DesktopStyle();
menuNames = viewWidth < breakpoint ? MobileMenuLables() : DesktopMenuLables();


const getLabel = (key) => {
    if (values[key])
        return values[key];
    return key;
}

const getmenuLabel = (key) => {
    if (menuNames[key])
        return menuNames[key];
    return key;
}

const ContentStyle = (key) => {
    if (style[key])
        return style[key];
    return key;
}

const mobileMode = () => {
    const mobile = viewWidth < breakpoint ? true : false;
    return mobile
}

const IsTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}


export { ContentStyle, getLabel, getmenuLabel, mobileMode, IsTouchDevice }