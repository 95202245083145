import PropTypes from "prop-types";
import { CButton } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { mobileMode } from "../Resources/content";
const AButton = (props) => {
  const isMobile = mobileMode();
  const {
    icon,
    iconClassName,
    isLoading,
    showSpinner = true,
    text,
    hideTextWhileLoading,
    ...attributes
  } = props;
  return (
    <CButton  size = {isMobile ? "sm":"md"} disabled={isLoading} {...attributes}>
      {hideTextWhileLoading && isLoading ? "" : text}
      {isLoading && showSpinner ? (
        <FontAwesomeIcon
          className={"fa-spin " + (text && 'ml-1')}
          icon={faSpinner}
        />
      ) : (
        <>{icon && <FontAwesomeIcon className={(text && 'ml-1') + iconClassName} icon={icon} />}</>
      )}
    </CButton>
  );
};

AButton.propTypes = {
  //It can be disabled the button and show the loading icon
  isLoading: PropTypes.bool,

  // It's SVG format icon , you  can find from font-awesome package
  icon: PropTypes.any,

  //ClassName for icon (ex: ml-1)
  iconClassName: PropTypes.string,

  //it can support text and html format
  text: PropTypes.any,

  //It can be hide the button text while loading 
  hideTextWhileLoading: PropTypes.bool,
};

AButton.defaultProps = {
  color: "",
  isLoading: false,
  size: "",
  icon: "",
  text: "",
  hideTextWhileLoading: false,
  iconClassName: "",
};

export default AButton;