import _ from "lodash";
import { getUserDetailsFromLocalStorage } from "./LocalStorageService";
//common method for  getting  the user pemission as per the module level feature  
const  getUserAccessPermission= (featureCode) => {
    let userInfo =getUserDetailsFromLocalStorage();
    let permissionList=userInfo?.access?.permission;
    let featureObject = _.find(permissionList, { code: featureCode });
    let permission = true;
    if(featureObject===undefined || featureObject===null || (featureObject?.access==="Enabled" && featureObject?.modeID===2)){
      permission = false;
    }
    return permission;
  };

export const readPermissionMessage = "User has only read permission please contact admin for write access";
export {getUserAccessPermission}

