/**
 * Use this Component for Creatable Select dropdown.
 * Component comntains all react-select(CreatableSelect) attributes
 */
 import { useSelector } from "react-redux";
 import { MultiSelect } from "react-multi-select-component";
 import "./Component.scss";

 
 const AMultiSelect = ({ ...props }) => {
   const darkMode = useSelector((state) => state.darkMode);
   let {className} = props;
   let {disabled} = props;
   if(darkMode && disabled){
    className += " dark_multiselect_disabled";
   }else if(darkMode){
    className += " dark_multiselect";
   }else if(!darkMode && disabled){
    className += " multiselect_disabled";
   }
   return (
     <MultiSelect
     {...props}
     className={className}
     />
   );
 };
 
 export default AMultiSelect;
 