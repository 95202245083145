const Request =(data)=>{
  const {
    clientId,
    guid,
    account,
    category,
    machineId,
    type,
    url,
    body,
    timeZoneStandardName,
    ip
  } = data;
    let payload =
       { 
        "clientID": clientId,
        "requestId":guid,
        "customerAccount": account,
        "Environment": process.env.REACT_APP_ENV === "dev" ? "Test" : "Production",
        "Version": "1.0",
        "Category": category,
        "RequestURL": url,
        "RequestType": type,
        "LogType":"Request",
        "Requestbody":type !== "GET" ? JSON.stringify(body) : "",
        "ThreadName": "3415",
        "IPAddress": ip,
        "MachineName": ip,
        "Reference1": "",
        "Reference2": "",
        "Reference3": "",
        "Reference4": "",
        "Reference5": "",
        "MachineMacID":machineId,
        "timeZoneStandardName": timeZoneStandardName,
      }
       return payload;
         
 }
 export {Request}