import { getAllViews, getModuleViews } from "../../WMS/Common_Helper/API_Endpoints";
import { v4 as uuidv4 } from 'uuid';
import createInterceptorInstance from "../../../Services/Interceptor";
import { RolesList, Users } from "../../Setup/Access.service"

function setHeaders() {
  return {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      // "Authorization": "Bearer "
    }
  }
}

async function postView(payload, logger) {
  const guid = uuidv4();
  let URL = createInterceptorInstance(getAllViews);
  try {
    const res = await URL.post('', payload, setHeaders());
    logger('debug', guid, res, "AIMS360RunwayWMS", getAllViews, "POST", payload); // log request and response data
    return res.data;
  } catch (error) {
    logger('error', guid, error, "AIMS360RunwayWMS", getAllViews, "POST", payload); // log error
    throw error;
  }
}

async function putView(payload, logger) {
  const guid = uuidv4();
  let URL = createInterceptorInstance(getAllViews);
  try {
    const res = await URL.put('', payload, setHeaders());
    logger('debug', guid, res, "AIMS360RunwayWMS", getAllViews, "POST", payload); // log request and response data
    return res.data;
  } catch (error) {
    logger('error', guid, error, "AIMS360RunwayWMS", getAllViews, "POST", payload); // log error
    throw error;
  }
}


async function getViews(logger, moduleId) {
  const guid = uuidv4();
  const viewUrl = getModuleViews.replace('{moduleId}', moduleId);
  let URL = createInterceptorInstance(viewUrl);
  try {
    const res = await URL.get("", setHeaders());
    logger('debug', guid, res, "AIMS360RunwayWMS", viewUrl, "POST", ""); // log request and response data
    return res.data;
  } catch (error) {
    logger('error', guid, error, "AIMS360RunwayWMS", viewUrl, "POST", ""); // log error
    throw error;
  }
}

async function getAllModuleViews(logger) {
  const guid = uuidv4();
  let URL = createInterceptorInstance(getAllViews);
  try {
    const res = await URL.get("", setHeaders());
    logger('debug', guid, res, "AIMS360RunwayWMS", getAllViews, "POST", ""); // log request and response data
    return res.data;
  } catch (error) {
    logger('error', guid, error, "AIMS360RunwayWMS", getAllViews, "POST", ""); // log error
    throw error;
  }
}


async function getRoles(logger) {
  const guid = uuidv4();
  const URL = createInterceptorInstance(RolesList);
  return URL.get('', setHeaders()).then(res => {
    logger('debug', guid, res, "AIMS360RunwayUserAccess", RolesList, "GET"); // log request and response data
    return res;
  }).catch(error => {
      logger('error', guid, error, "AIMS360RunwayUserAccess", RolesList, "GET"); // log error
      throw error;
    });
};

async function getUsers(logger){
  const guid = uuidv4();
  const URL = createInterceptorInstance(Users);
  return URL.get('', setHeaders())?.then(res => {
      logger('debug', guid, res, "AIMS360RunwayUserAccess", Users, "GET"); // log request and response data
      return res;
  })
  .catch(error => {
      logger('error', guid, error, "AIMS360RunwayUserAccess", Users, "GET"); // log error
      throw error;
  });
}

async function deleteView(viewId, logger){
  const guid = uuidv4();
  
  let deleteUrl = `${getAllViews}/delete/${viewId}`
  const URL = createInterceptorInstance(deleteUrl);
  return URL.delete('', setHeaders())?.then(res => {
      logger('debug', guid, res, "AIMS360RunwayUserAccess", deleteUrl, "GET"); // log request and response data
      return res;
  })
  .catch(error => {
      logger('error', guid, error, "AIMS360RunwayUserAccess", deleteUrl, "GET"); // log error
      throw error;
  });
}

export {
  postView,
  getViews,
  getRoles,
  getUsers,
  putView,
  deleteView,
  getAllModuleViews,
}